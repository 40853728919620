<template>
  <TableHeadersStyled>
    <ButtonStyled @click="$emit('prevColumn')" :disabled="prevDisabled">
      <ChevronLeftIcon />
    </ButtonStyled>
    <Multiselect
      :options="columns"
      :value="selectedColumn"
      trackBy="id"
      label="label"
      :placeholder="$tc('column', 2)"
      :hide-selected="true"
      :select-label="''"
      :multiple="false"
      class="semi-darker"
      @select="$emit('selectColumn', $event)"
    />
    <ButtonStyled @click="$emit('nextColumn')" :disabled="nextDisabled">
      <ChevronRightIcon />
    </ButtonStyled>
  </TableHeadersStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { ChevronLeftIcon, ChevronRightIcon } from 'vue-feather-icons'

import { flexCenter, buttonReset } from '@styles/mixins'

import Multiselect from 'vue-multiselect'

const TableHeadersStyled = styled('div')`
  ${flexCenter}
  height: calc(3rem - 2px);
`

const ButtonStyled = styled('div')`
  ${buttonReset}
  ${flexCenter}
  color: ${p => p.theme.colors.primary};
  flex-shrink: 0;
  width: 3rem;
`

export default {
  props: {
    prevDisabled: {
      type: Boolean,
      required: true,
    },
    nextDisabled: {
      type: Boolean,
      required: true,
    },
    selectedColumn: {
      type: Object,
    },
    columns: {
      type: Array,
      required: true,
    },
  },
  components: {
    TableHeadersStyled,
    ChevronLeftIcon,
    ChevronRightIcon,
    Multiselect,
    ButtonStyled,
  },
}
</script>
