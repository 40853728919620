<template>
  <TableSmallMainOverviewStyled>
    <RowsStyled :totals="totals">
      <RowStyled v-for="(cells, key) in cellsFromRow" :key="cells[1].rowId">
        <TableDataCell class="primary" :cell="cells[0]" :key="`primary_${cells[0].id}`" :isMobile="isMobile" :cellIndex="key">
          <template v-slot:panelButton>
            <button @click="togglePanel(cells[1].rowId)" :key="cells[1].rowId">
              <ChevronRightIcon :class="{ 'rotate-down': id === cells[1].rowId, 'rotate-right': id === null }" />
            </button>
          </template>
        </TableDataCell>
        <TableDataCell class="dataCol" :cell="cells[1]" :key="`dataCol_${cells[1].id}`" @click="$emit('selectRow', cells[1])" />
        <TableSubPanel
          :key="cells[1].rowId"
          v-if="showPanel && id === cells[1].rowId && panelComponent"
          :panelContent="cells[1].panelContent"
          :customPanelComponent="panelComponent"
          :isMobile="isMobile"
          class="panel"
        />
      </RowStyled>
    </RowsStyled>
    <template v-if="totals">
      <template v-for="cells in cellsFromTotal">
        <TotalRowStyled v-if="cells[1].sortableValue" :key="cells[1].rowId">
          <TableDataCell class="primary" :cell="cells[0]" :key="`primary_${cells[0].id}`" :isMobile="isMobile" />
          <TableDataCell class="dataCol" :cell="cells[1]" :key="`dataCol_${cells[1].id}`" />
        </TotalRowStyled>
      </template>
    </template>
  </TableSmallMainOverviewStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import localesMixin from '@/mixins/locales'
import { buttonReset, flexCenter } from '@styles/mixins'
import { ChevronRightIcon } from 'vue-feather-icons'
import TableDataCell from '../../../../Atoms/TableCells/TableDataCell.vue'
import TableSubPanel from '../../TableSubPanel.vue'

const TableSmallMainOverviewStyled = styled('div')`
  display: grid;
  width: 100%;
  height: 100%;
  grid-gap: 2px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
`

const RowsStyled = styled('div')`
  display: grid;
  height: ${p => (p.totals ? 'calc(100% - 3rem)' : '100%')};
  overflow-y: auto;
  grid-template-columns: 1fr;
  grid-gap: 2px;
  grid-auto-rows: minmax(3rem, auto);
`

const RowStyled = styled('div')`
  ${buttonReset}
  flex-grow: 1;
  display: grid;
  grid-template-areas:
    'primary dataCol'
    'panel panel';
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1.5fr;
  background: ${({ theme }) => theme.colors.solidBG};
  padding: 0.5rem;
  border-bottom: solid 1px ${p => p.theme.colors.atomic.tableColumnHeaderBG};
  .primary {
    ${flexCenter}
    color: ${p => p.theme.colors.primary};
    justify-content: flex-start;
    grid-area: primary;
    > button {
      ${buttonReset}
      ${flexCenter}
      color: ${({ theme }) => theme.colors.atomic.primary};
      padding-left: 0.25rem;
      > .feather {
        transition: 0.25s transform;
        &.rotate-right {
          transform: rotate(0deg);
        }
        &.rotate-down {
          transform: rotate(90deg);
        }
      }
    }
  }
  .dataCol {
    ${buttonReset}
    ${flexCenter}
    justify-content: flex-end;
    font-size: 0.8rem;
    grid-area: dataCol;
  }
  .panel {
    grid-area: panel;
  }
  &:hover {
    .name,
    .type,
    .value {
      color: ${p => p.theme.colors.white};
    }
    color: ${p => p.theme.colors.atomic.textMain};
  }
`
const TotalRowStyled = styled('div')`
  position: fixed;
  bottom: 40px;
  ${buttonReset}
  flex-grow: 1;
  display: grid;
  grid-template-areas: 'primary dataCol';
  grid-template-rows: 100%;
  grid-template-columns: 1fr 1.5fr;
  padding: 0.5rem;
  border-bottom: solid 1px ${p => p.theme.colors.atomic.tableColumnHeaderBG};
  border-top: solid 2px ${({ theme }) => theme.colors.atomic.gunmetal};
  background: ${({ theme }) => theme.colors.atomic.tableTotal};
  .primary {
    ${flexCenter}
    color: ${p => p.theme.colors.primary};
    justify-content: flex-start;
    grid-area: primary;
  }
  .dataCol {
    ${flexCenter}
    justify-content: flex-end;
    font-size: 0.8rem;
    grid-area: dataCol;
  }
  width: calc(100% - 1.6rem);
`

export default {
  mixins: [localesMixin],
  props: {
    rows: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    selectedColumn: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    totals: {
      type: Array,
      required: false,
    },
    panelComponent: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      id: null,
      showPanel: false,
    }
  },
  components: {
    TableSmallMainOverviewStyled,
    RowsStyled,
    RowStyled,
    TableDataCell,
    TotalRowStyled,
    ChevronRightIcon,
    TableSubPanel,
  },
  computed: {
    cellsFromRow() {
      const cellOfRows = this.rows.map(row => {
        const cells = get(row, 'cells', [])
        const panelContent = get(row, 'panelContent', [])
        const col = cells.find(c => c.headerId === this.selectedColumn.id)
        const primaryCol = cells.find(c => c.isMobilePrimarycol === true)
        return [
          primaryCol,
          {
            rowId: row.rowId,
            ...col,
            panelContent,
          },
        ]
      })
      return cellOfRows
    },
    cellsFromTotal() {
      const cellOfTotals = this.totals.map(row => {
        const cells = get(row, 'cells', [])
        const col = cells.find(c => c.headerId === this.selectedColumn.id)
        if (this.isMobile) {
          col.id === 'total_name' ? (col.sortableValue = '') : col.sortableValue
        }
        const label = { sortableValue: 'Total' }
        return [
          label,
          {
            rowId: row.rowId,
            ...col,
          },
        ]
      })
      return cellOfTotals
    },
  },
  methods: {
    togglePanel(id) {
      if (this.id === id) {
        this.id = null
        this.showPanel = false
      } else {
        this.id = id
        this.showPanel = true
      }
    },
  },
}
</script>
