<template>
  <WrapperStyled class="cell">
    <slot name="panelButton" v-if="cell.panel" />
    <component v-if="cell.component !== undefined" :is="cell.component" :cell="cell" :isMobile="isMobile" :panel="cell.panel" />
    <PlainCellStyled :isMobile="isMobile" :panel="cell.panel" v-else>
      {{ cell.sortableValue }}
    </PlainCellStyled>
  </WrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const PlainCellStyled = styled('div')`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  overflow: hidden;
  @media (min-width: 1024px) {
    ${({ isMobile, panel }) => !isMobile && !panel && `padding-left: 3rem`};
  }
`
const WrapperStyled = styled('div')`
  display: flex;
  align-items: center;
`
export default {
  props: {
    cell: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    cellIndex: {
      type: Number,
      required: false,
    },
  },
  components: {
    PlainCellStyled,
    WrapperStyled,
  },
}
</script>
