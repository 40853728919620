var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TableMainStyled', [_c('transition', {
    attrs: {
      "name": "vertical"
    }
  }, [!_vm.selectedRow ? _c('TableSmallColumnData', {
    attrs: {
      "rows": _vm.rows,
      "columns": _vm.columns,
      "selectedColumn": _vm.selectedColumn,
      "isMobile": _vm.isMobile,
      "totals": _vm.totals,
      "panelComponent": _vm.panelComponent
    },
    on: {
      "selectRow": function selectRow($event) {
        return _vm.$emit('selectRow', $event);
      }
    }
  }) : _c('TableSmallRowData', {
    staticClass: "table-details",
    attrs: {
      "row": _vm.selectedRow,
      "columns": _vm.columns
    },
    on: {
      "selectRow": function selectRow($event) {
        return _vm.$emit('selectRow', $event);
      }
    }
  })], 1), _vm.isLoading ? _c('LoadingStyled', [_c('TalpaLoaderWrapper', {
    attrs: {
      "isDarker": true
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }