<template>
  <TableLargeV2
    v-if="isLarge"
    :title="title"
    :headers="headers"
    :rows="rows"
    :sorting="sorting"
    :filtersAvailable="filtersAvailable"
    :filterOptionsSelected="filterOptionsSelected"
    :dropDownSelectorsAvailable="dropDownSelectorsAvailable"
    :dropDownSelectors="dropDownSelectors"
    :customOptions="customOptions"
    :customLabels="customLabels"
    :isLoading="isLoading"
    :searchQuery="searchQuery"
    :enableSearch="enableSearch"
    :totals="totals"
    :isMobile="isMobile"
    :enableFooter="enableFooter"
    :messages="messages"
    :fixedFilter="fixedFilter"
    :filtersLoading="filtersLoading"
    :panelComponent="panelComponent"
    :hasSavedFilters="hasSavedFilters"
    :isRowSelectable="isRowSelectable"
    :selectedRowId="selectedRowId"
    :badgeText="badgeText"
    :noTitleCapitalize="noTitleCapitalize"
    :preselectionId="preselectionId"
    :disableTitleBar="disableTitleBar"
    :showLoadMore="showLoadMore"
    :totalRowCount="totalRowCount"
    @tableExport="$emit('tableExport', $event)"
    @update:sorting="$emit('update:sorting', $event)"
    @setSelectedFilterOption="$emit('setSelectedFilterOption', $event)"
    @resetFilters="$emit('resetFilters')"
    @saveFilters="$emit('saveFilters')"
    @deleteFilters="$emit('deleteFilters')"
    @textFilter="$emit('textFilter', $event)"
    @copyToClipboard="$emit('copyToClipboard', $event)"
    @dropdownSelectorChange="$emit('dropdownSelectorChange', $event)"
    @set-selected-row-id="$emit('set-selected-row-id', $event)"
    @load-more-rows="$emit('load-more-rows', $event)"
  >
  </TableLargeV2>
  <TableSmallV2
    v-else
    :title="title"
    :headers="headers"
    :rows="rows"
    :sorting="sorting"
    :filtersAvailable="filtersAvailable"
    :filterOptionsSelected="filterOptionsSelected"
    :dropDownSelectorsAvailable="dropDownSelectorsAvailable"
    :dropDownSelectors="dropDownSelectors"
    :customOptions="customOptions"
    :customLabels="customLabels"
    :isLoading="isLoading"
    :searchQuery="searchQuery"
    :enableSearch="enableSearch"
    :totals="totals"
    :isMobile="isMobile"
    :enableFooter="enableFooter"
    :messages="messages"
    :fixedFilter="fixedFilter"
    :panelComponent="panelComponent"
    :hasSavedFilters="hasSavedFilters"
    :badgeText="badgeText"
    :preselectionId="preselectionId"
    :disableTitleBar="disableTitleBar"
    :showLoadMore="showLoadMore"
    :totalRowCount="totalRowCount"
    @tableExport="$emit('tableExport', $event)"
    @update:sorting="$emit('update:sorting', $event)"
    @setSelectedFilterOption="$emit('setSelectedFilterOption', $event)"
    @resetFilters="$emit('resetFilters')"
    @saveFilters="$emit('saveFilters')"
    @deleteFilters="$emit('deleteFilters')"
    @textFilter="$emit('textFilter', $event)"
    @dropdownSelectorChange="$emit('dropdownSelectorChange', $event)"
    @load-more-rows="$emit('load-more-rows', $event)"
  />
</template>

<script>
import TableLargeV2 from './TableV2/TableLargeV2.vue'
import TableSmallV2 from './TableV2/TableSmallV2.vue'

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    sorting: {
      type: Object,
      required: true,
    },
    filtersAvailable: {
      type: Array,
      required: true,
    },
    filterOptionsSelected: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
    },
    searchQuery: {
      type: String,
      required: false,
    },
    enableSearch: {
      type: Boolean,
      default: false,
      required: true,
    },
    totals: {
      type: Array,
      required: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    enableFooter: {
      type: Boolean,
      default: false,
    },
    messages: {
      type: String,
      required: false,
    },
    dropDownSelectorsAvailable: {
      type: Boolean,
      default: false,
    },
    dropDownSelectors: {
      type: Array,
      required: false,
    },
    customOptions: {
      type: String,
      required: false,
    },
    customLabels: {
      type: String,
      required: false,
    },
    fixedFilter: {
      type: Boolean,
      default: false,
    },
    filtersLoading: {
      type: Boolean,
      default: false,
    },
    panelComponent: {
      type: String,
      required: false,
    },
    hasSavedFilters: {
      type: Boolean,
      default: false,
      required: false,
    },
    isRowSelectable: {
      type: Boolean,
      default: false,
      required: false,
    },
    selectedRowId: {
      type: String,
      required: false,
    },
    badgeText: {
      type: String,
      required: false,
    },
    noTitleCapitalize: {
      type: Boolean,
      default: false,
    },
    preselectionId: {
      type: String,
      required: false,
    },
    disableTitleBar: {
      type: Boolean,
      default: false,
    },
    showLoadMore: {
      type: Boolean,
      default: false,
    },
    totalRowCount: {
      type: Number,
    },
  },
  components: {
    TableLargeV2,
    TableSmallV2,
  },
  data() {
    return {
      isLarge: true,
    }
  },
  mounted() {
    this.onResize()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  watch: {
    isMobile: {
      handler() {
        this.onResize()
      },
      immediate: true,
    },
  },
  methods: {
    onResize() {
      if (window.innerWidth < 1024 || this.isMobile) {
        this.isLarge = false
      } else {
        this.isLarge = true
      }
    },
  },
}
</script>
