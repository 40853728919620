<template>
  <div :class="styles">{{ label }}</div>
</template>

<script>
import { css } from '@emotion/css'
import chroma from 'chroma-js'

const BadgeStyles = ({ fontColor, badgeColor }) => css`
  color: ${fontColor};
  background-color: ${badgeColor};
  padding: 0.4rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 12px;
  text-align: center;
  transition: color 0.5s, background-color 0.5s;
`

export default {
  inject: ['theme'],
  props: {
    label: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styles() {
      return BadgeStyles({
        fontColor: this.fontColor,
        badgeColor: this.badgeColor,
      })
    },
    fontColor() {
      let fontColor = this.color !== undefined ? this.theme.colors.white : this.theme.colors.normalFontColor
      if (this.isDisabled) {
        fontColor = chroma(fontColor).alpha(0.5).css()
      }
      return fontColor
    },
    badgeColor() {
      let badgeColor = this.theme.colors.atomic.badge
      if (this.color !== undefined) {
        badgeColor = this.color
      }
      if (this.isDisabled) {
        badgeColor = chroma(badgeColor).desaturate(1).css()
      }
      return badgeColor
    },
  },
}
</script>
