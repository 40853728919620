<template>
  <TableSmallV2Styled :totals="totals" :enableFooter="enableFooter" :badgeText="badgeText" :disableTitleBar="disableTitleBar">
    <header v-if="!disableTitleBar">
      <div class="title-bar" v-if="!selectedRow">
        <span class="title">
          {{ title }}
        </span>
        <CircleBadge :label="rows.length" :totalRowCount="totalRowCount" size="M" />
        <BadgeAtom :label="badgeText" class="badge" v-if="badgeText" />
      </div>
      <div v-else>
        <button class="styleless" @click="selectRow(null)">
          <ChevronUpIcon />
          <span class="backTitle">{{ $t('actions.backToOverview') }}</span>
        </button>
      </div>
      <div class="spacer" />
      <SearchIcon @click="toggleSearchTray" v-if="enableSearch" data-testid="tableSmallSearch" />
      <div class="dropdown" v-if="dropDownSelectorsAvailable">
        <DropdownSelectorV2
          :availableSelectors="dropDownSelectors"
          :customOptions="customOptions"
          :customLabels="customLabels"
          :preselectionId="preselectionId"
          data-testid="tableSmallDropdownSelector"
          @selectionChanged="$emit('dropdownSelectorChange', $event)"
        />
      </div>
      <button class="icon" @click="enableFilters" v-if="filtersAvailable.length > 0">
        <TalpaIcon class="filter-icon" :scope="'Feed'" :name="'FilterIcon'" />
        <CircleBadge class="filter-badge" :label="filterOptionsSelected.length" size="S" />
      </button>
      <transition name="fade">
        <SearchTrayStyled v-if="showSearchTray">
          <div class="search">
            <SearchBar
              :searchQuery="searchQuery"
              @filterShareList="$emit('textFilter', $event)"
              :placeHolder="'actions.search'"
              data-testid="tableSmallSearchInput"
            />
            <XIcon size="2x" @click="toggleSearchTray" />
          </div>
        </SearchTrayStyled>
      </transition>
      <transition name="fade">
        <FilterOverlayStyled v-if="showFilters">
          <div class="filter-top">
            <span> {{ $tc('feed.filter.title') }}:</span>
            <CircleBadge :label="filterOptionsSelected.length" size="S" />
            <div class="spacer"></div>
            <FilterActionStyled :fixedFilter="fixedFilter">
              <template v-if="fixedFilter">
                <button class="styleless" @click="saveFilters" v-tooltip="tooltip('save')"><SaveIcon size="1.3x" /></button>
                <div class="divider" />
                <button class="hide" @click="resetFilters" v-tooltip="tooltip('reset')"><RotateCcwIcon size="1.2x" /></button>
                <div class="hide" />
                <button class="styleless" @click="deleteFilters" v-tooltip="tooltip('delete')" :disabled="!hasSavedFilters">
                  <Trash2Icon size="1.2x" />
                </button>
                <div class="divider" />
                <button class="icon" @click="disableFilters" v-tooltip="tooltip('close')">
                  <XIcon />
                </button>
              </template>
              <template v-else>
                <button class="styleless" @click="resetFilters">
                  {{ $tc('reset') }}
                </button>
                <span>|</span>
                <button class="icon" @click="disableFilters">
                  <XIcon />
                </button>
              </template>
            </FilterActionStyled>
          </div>
          <div class="filters">
            <CustomMultiSelect
              v-for="filter in filtersAvailable"
              :key="filter.id"
              track-by="id"
              openDirection="bottom"
              :class="filter.class"
              :label="filter.label"
              :value="filter.value"
              :options="filter.options"
              :shouldNotResetSelectedOption="true"
              :disabled="filter.options.length === 0"
              :closeOnSelect="!filter.isMultiple"
              :searchable="filter.searchable"
              :multiple="filter.isMultiple"
              :placeholder="filter.placeholder"
              :modelName="filter.modelName"
              :maxHeight="256"
              :optionsLimit="6"
              :applyFilter="false"
              :internalSearch="true"
              :customTagSlot="true"
              :customNoOptionsSlot="true"
              @select="$emit('setSelectedFilterOption', $event)"
              @remove="$emit('setSelectedFilterOption', $event)"
            >
              <template v-slot:customLabelIcon>
                <ShowAsLabel>{{ $tc(filter.modelName, 1) }}:</ShowAsLabel>
              </template>
              <template v-slot:tag="option">
                <SelectedTagStyled>
                  <strong v-if="filter.value.length === 1">
                    {{ filter.value[0].label }}
                  </strong>
                  <strong v-else-if="filter.value[0].id === option.option.id">{{ filter.placeholder }} {{ filter.value.length }}</strong>
                  <span v-else />
                </SelectedTagStyled>
              </template>
              <template v-slot:noOptions>
                <strong>{{ filter.placeholder }}</strong>
              </template>
            </CustomMultiSelect>
          </div>
        </FilterOverlayStyled>
      </transition>
    </header>
    <TableSmallHeaderSelector
      class="header-selector"
      :rows="rows"
      :selectedRow="selectedRow"
      :prevRowDisabled="rows.length < 2"
      :nextRowDisabled="rows.length < 2"
      :prevColumnDisabled="columns.length < 2"
      :nextColumnDisabled="columns.length < 2"
      :selectedColumn="selectedColumn"
      :columns="columns"
      @selectColumn="selectColumn"
      @prevColumn="prevColumn"
      @nextColumn="nextColumn"
      @prevRow="prevRow"
      @nextRow="nextRow"
      @selectRow="selectRow"
    />

    <main id="mobile-main">
      <TalpaLoaderWrapper v-if="isLoading" />
      <TableSmallDataRows
        v-if="selectedColumn && !isLoading && rows.length"
        class="data-rows"
        :isLoading="isLoading"
        :selectedRow="selectedRow"
        :rows="rows"
        :columns="columns"
        :selectedColumn="selectedColumn"
        :isMobile="isMobile"
        :totals="totals"
        :panelComponent="panelComponent"
        @selectRow="selectRow"
      />
      <LoadMoreButtonMolecule v-if="showLoadMore" @load-more-rows="$emit('load-more-rows', $event)" class="loadmore" />
      <NoDataStyled v-else-if="!isLoading && !rows.length">{{ messages }}</NoDataStyled>
      <ScrollToTopButtonMolecule :containerId="'mobile-main'" :scrollThreshold="1000" class="scroll-up" />
    </main>

    <footer v-if="enableFooter && !isLoading && rows.length">
      <DownloadIcon @click="$emit('tableExport', 'csv')" />
    </footer>
  </TableSmallV2Styled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'

import { buttonReset, flexCenter } from '@styles/mixins'
import { TalpaIcon, LoadMoreButtonMolecule, ScrollToTopButtonMolecule } from '@common/components'
import CircleBadge from '../../Atoms/CircleBadge'
import TableSmallHeaderSelector from './TableSmallV2/TableSmallHeaderSelector'
import TableSmallDataRows from './TableSmallV2/TableSmallDataRows'
import { ChevronUpIcon, XIcon, SearchIcon, DownloadIcon, SaveIcon, RotateCcwIcon, Trash2Icon } from 'vue-feather-icons'
import SearchBar from '@/components/Atomic/Molecules/SearchBar'
import { CustomMultiSelect, TalpaLoaderWrapper } from '@common/components'
import DropdownSelectorV2 from '@/components/Atomic/Molecules/DropdownSelectorV2'
import { VTooltip } from 'v-tooltip'
import BadgeAtom from '@/components/Atomic/Atoms/BadgeAtom'

const TableSmallV2Styled = styled('div')`
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  height: 100%;
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem 3rem auto;

  ${({ disableTitleBar }) => disableTitleBar && `grid-template-rows: 3rem auto;`};
  ${({ enableFooter, disableTitleBar }) => enableFooter && !disableTitleBar && `grid-template-rows: 3rem 3rem auto 2rem`};
  ${({ enableFooter, disableTitleBar }) => enableFooter && disableTitleBar && `grid-template-rows:  3rem auto 2rem`};
  > header {
    position: relative;
    padding: 0.25rem 1rem;
    display: grid;
    grid-template-columns: 1fr min-content min-content min-content;
    grid-template-rows: 2.5rem;
    grid-gap: 0.5rem;
    align-items: center;

    .backTitle {
      width: max-content;
      margin-left: 0.5rem;
      color: ${({ theme }) => theme.colors.atomic.textMain};
    }

    .title-bar {
      display: grid;
      grid-template-columns: max-content min-content;
      ${({ badgeText }) => badgeText && `grid-template-columns: max-content min-content max-content ;`};
      grid-template-rows: 2.5rem;
      grid-gap: 0.5rem;
      align-items: center;

      > .title {
        flex-grow: 1;
        display: flex;
        font-weight: bold;
        text-transform: capitalize;
      }
    }
    > .feather-search {
      color: ${({ theme }) => theme.colors.primary};
      transform: rotateZ(90deg);
      cursor: pointer;
      &:hover {
        color: ${props => props.theme.colors.primaryActive};
      }
    }
    > .dropdown {
      > div {
        background: ${({ theme }) => (theme.isDark ? theme.colors.atomic.bunker : chroma(theme.colors.navFontNormal).alpha(0.2).css())};
        height: 2.5rem;
        width: auto;
      }
    }
    button.icon,
    button.styleless {
      ${buttonReset}
      ${flexCenter}
      padding: 0;
      color: ${({ theme }) => theme.colors.atomic.primary};
    }
    .hide {
      display: none;
    }
    button.icon {
      width: 1.25rem;
      height: 2rem;
      > .filter-icon {
        width: 1.25rem;
        height: 1.25rem;
        > svg {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
      > .filter-badge {
        position: absolute;
        top: 0.2rem;
        right: 0.2rem;
        background: ${({ theme }) => theme.colors.atomic.filterBadge};
      }
    }

    button.info {
      top: 0.5rem;
      right: 3.25rem;
      display: flex;
      position: absolute;
    }
  }
  > main {
    position: relative;
    overflow: auto;
    padding-bottom: 10px;
    .loadmore {
      position: absolute;
    }
    .scroll-up {
      right: 5rem;
    }
  }

  > footer {
    background: ${({ theme }) => theme.colors.atomic.tableBG};
    position: relative;
    padding: 0.25rem 1rem;
    justify-content: flex-end;
    display: flex;
    > .feather {
      ${buttonReset}
      color: ${({ theme }) => theme.colors.atomic.primary};
      align-self: center;
      cursor: pointer;
    }
  }

  .header-selector {
    border-top: solid 1px ${p => p.theme.colors.atomic.mirage};
    border-bottom: solid 1px ${p => p.theme.colors.atomic.mirage};
  }
`

const FilterOverlayStyled = styled('div')`
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 10;
  width: calc(100% - 1rem);
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  color: ${({ theme }) => theme.colors.primary};
  padding: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  box-shadow: -1px 0px 19px 1px #00000036;
  .filter-top {
    display: grid;
    grid-template-columns: min-content min-content 1fr max-content;
    grid-template-rows: 3rem;
    grid-gap: 0.5rem;
    align-items: center;
    line-height: 2rem;
    padding: 0.5rem;
  }
  .filters {
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-columns: auto;
    grid-auto-flow: row;
    grid-gap: 1rem;
    align-items: center;
    line-height: 2rem;
    .filter {
      .multiselect {
        min-height: 0;
        margin: 0;
        color: ${({ theme }) => theme.colors.atomic.textMain};
        .multiselect__tags {
          background: ${({ theme }) => theme.colors.atomic.bunkerLight};
          &:hover {
            background: ${({ theme }) => theme.colors.atomic.tableColumnHeaderBG};
          }
          border-radius: 3rem;
          padding: 0.1rem 2.25rem 0.1rem 1rem;
          min-width: 13rem;
          min-height: 0;
          height: 2.5rem;
          .multiselect__single {
            top: 0.4rem;
            font-size: 14px;
            font-weight: bold;
            padding: 0;
            margin: 0;
          }
          .multiselect__element {
            background: ${({ theme }) => theme.colors.atomic.mirage};
          }
          .multiselect__placeholder {
            padding: 0;
            margin: 0;
            color: ${({ theme }) => theme.colors.atomic.textMain};
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
    }
  }
`
const SearchTrayStyled = styled('div')`
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 10;
  width: calc(100% - 1rem);
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  color: ${({ theme }) => theme.colors.primary};
  padding: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  box-shadow: -1px 0px 19px 1px #00000036;
  > .search {
    padding: 0 0.5rem;
    display: flex;
    gap: 0.5rem;
    > .feather-x {
      align-self: center;
      padding-left: 0.2rem;
    }
    > div {
      input {
        background: ${({ theme }) => theme.colors.atomic.bunker};
      }
      height: 2.5rem;
      width: 100%;
    }
  }
`
const ShowAsLabel = styled('span')`
  color: ${({ theme }) => theme.colors.atomic.textMain};
`
const NoDataStyled = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  color: ${({ theme }) => theme.colors.atomic.textMain};
`
const FilterActionStyled = styled('div')`
  grid-template-columns: max-content min-content min-content;
  ${({ fixedFilter }) => fixedFilter && `grid-template-columns: max-content min-content max-content min-content max-content;`};

  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.atomic.toolsMenuBG};
  ${({ isMobile }) => isMobile && 'margin-left: 0; margin-bottom: 0.5rem; position:absolute; bottom:3.5rem;'};
  border-radius: 8px;
  justify-content: space-evenly;
  color: ${({ theme }) => theme.colors.primary};
  box-shadow: 0px 3px 6px ${({ theme }) => chroma(theme.colors.black).alpha(0.32).css()};
  border: 1px solid
    ${({ theme }) => (theme.isDark ? chroma(theme.colors.white).alpha(0.16).css() : chroma(theme.colors.black).alpha(0.16).css())};
  padding: 0 0.5rem;
  .divider {
    display: flex;
    margin: 0.5rem;
    width: 1px;
    height: 25px;
    background: ${({ theme }) => theme.colors.atomic.divider};
  }
`
const SelectedTagStyled = styled('span')`
  color: ${({ theme }) => theme.colors.atomic.primary};
`
export default {
  components: {
    TableSmallV2Styled,
    TalpaIcon,
    CircleBadge,
    TableSmallHeaderSelector,
    TableSmallDataRows,
    ChevronUpIcon,
    FilterOverlayStyled,
    ShowAsLabel,
    CustomMultiSelect,
    XIcon,
    TalpaLoaderWrapper,
    SearchBar,
    SearchIcon,
    SearchTrayStyled,
    DownloadIcon,
    NoDataStyled,
    DropdownSelectorV2,
    FilterActionStyled,
    SaveIcon,
    RotateCcwIcon,
    Trash2Icon,
    SelectedTagStyled,
    BadgeAtom,
    LoadMoreButtonMolecule,
    ScrollToTopButtonMolecule,
  },
  directives: {
    tooltip: VTooltip,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    sorting: {
      type: Object,
      required: true,
    },
    filtersAvailable: {
      type: Array,
      required: true,
    },
    filterOptionsSelected: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
    },
    searchQuery: {
      type: String,
      required: false,
    },
    enableSearch: {
      type: Boolean,
      default: false,
      required: true,
    },
    totals: {
      type: Array,
      required: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    enableFooter: {
      type: Boolean,
      default: false,
    },
    messages: {
      type: String,
      required: false,
    },
    dropDownSelectorsAvailable: {
      type: Boolean,
      default: false,
    },
    dropDownSelectors: {
      type: Array,
      require: false,
    },
    customOptions: {
      type: String,
      required: false,
    },
    customLabels: {
      type: String,
      required: false,
    },
    fixedFilter: {
      type: Boolean,
      default: false,
    },
    panelComponent: {
      type: String,
      required: false,
    },
    hasSavedFilters: {
      type: Boolean,
      default: false,
      required: false,
    },
    badgeText: {
      type: String,
      required: false,
    },
    preselectionId: {
      type: String,
      required: false,
    },
    disableTitleBar: {
      type: Boolean,
      default: false,
    },
    showLoadMore: {
      type: Boolean,
      default: false,
    },
    totalRowCount: {
      type: Number,
    },
  },

  data() {
    return {
      selectedRow: null,
      selectedColumn: null,
      showFilters: false,
      showSearchTray: false,
    }
  },
  mounted() {
    this.selectedColumn = this.columns[0]
  },
  computed: {
    columns() {
      return this.headers.map(h => {
        const unit = h.unit ? `(${h.unit})` : ''
        return {
          ...h,
          label: `${h.label.toUpperCase()} ${unit}`,
        }
      })
    },
    localSearchQuery: {
      get() {
        return this.searchQuery
      },
      set(value) {
        this.$emit('textFilter', value)
      },
    },
    displayMessage() {
      return this.messages !== ''
    },
  },
  methods: {
    enableFilters() {
      this.showFilters = !this.showFilters
    },
    resetFilters() {
      this.$emit('resetFilters')
    },
    disableFilters() {
      this.showFilters = false
    },
    prevColumn() {
      const idx =
        this.columns.indexOf(this.selectedColumn) - 1 >= 0 ? this.columns.indexOf(this.selectedColumn) - 1 : this.columns.length - 1
      this.selectedColumn = this.columns[idx]
    },
    nextColumn() {
      const idx =
        this.columns.indexOf(this.selectedColumn) + 1 <= this.columns.length - 1 ? this.columns.indexOf(this.selectedColumn) + 1 : 0
      this.selectedColumn = this.columns[idx]
    },
    prevRow() {
      const idx = this.rows.indexOf(this.selectedRow) - 1 > 0 ? this.rows.indexOf(this.selectedRow) - 1 : this.rows.length - 1
      this.selectedRow = this.rows[idx]
    },
    nextRow() {
      const idx = this.rows.indexOf(this.selectedRow) + 1 <= this.rows.length - 1 ? this.rows.indexOf(this.selectedRow) + 1 : 0
      this.selectedRow = this.rows[idx]
    },
    selectRow(cell) {
      if (cell === null) {
        this.selectedRow = null
        return
      }
      this.selectedRow = this.rows.find(r => r.rowId === cell.rowId)
    },
    selectColumn(col) {
      this.selectedColumn = col
    },
    toggleSearchTray() {
      this.showSearchTray = !this.showSearchTray
    },
    tooltip(mode) {
      return {
        content: this.toolTipContent(mode),
        placement: 'bottom',
        classes: 'dashboard',
        trigger: 'hover',
        container: '#app',
      }
    },
    toolTipContent(mode) {
      if (mode === 'save') {
        return this.$tc('table.filter.tooltip.save')
      } else if (mode === 'reset') {
        return this.$tc('table.filter.tooltip.reset')
      } else if (mode === 'delete') {
        return this.$tc('table.filter.tooltip.delete')
      } else if (mode === 'close') {
        return this.$tc('table.filter.tooltip.close')
      }
    },
    saveFilters() {
      this.$emit('saveFilters')
    },
    deleteFilters() {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        confirmText: this.$t('table.filter.deleteInfoMessage'),
        onConfirm: this.confirmDelete,
      })
    },
    confirmDelete() {
      this.$emit('deleteFilters')
      return 'delete'
    },
  },
}
</script>
