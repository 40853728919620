export const API_AUTH_TOKEN = 'talpa-auth-token'
export const API_AUTH_STATE = 'talpa-api-auth-state'
export const API_AUTH_REDIRECT = 'talpa-api-auth-redirect'

export const TABLE_SORT_TYPES = {
  A_TO_Z: 'A_TO_Z', //alphabet sorting
  NEW_TO_OLD: 'NEW_TO_OLD', // use this for dates
  GOOD_TO_CRITICAL: 'GOOD_TO_CRITICAL', // not used anymore
  OK_TO_ERROR: 'OK_TO_ERROR', // use this is for health
  LOW_TO_HIGH: 'LOW_TO_HIGH', // use this for numbers
  FRONT_TO_BACK: 'FRONT_TO_BACK', // use this for tyres
}
