<template>
  <TableHeadersStyled :class="{ detailed: selectedRow }">
    <transition name="vertical" class="foobar">
      <TableSmallHeadersColumnSelector
        v-if="!selectedRow"
        :prevDisabled="prevColumnDisabled"
        :nextDisabled="nextColumnDisabled"
        :selectedColumn="selectedColumn"
        :columns="columns"
        @selectColumn="$emit('selectColumn', $event)"
        @prevColumn="$emit('prevColumn')"
        @nextColumn="$emit('nextColumn')"
      />
      <TableSmallHeadersRowSelector
        v-else
        class="table-details"
        :prevDisabled="prevRowDisabled"
        :nextDisabled="nextRowDisabled"
        :selectedRow="selectedRow"
        :rows="rows"
        @selectRow="$emit('selectRow', $event)"
        @prevRow="$emit('prevRow')"
        @nextRow="$emit('nextRow')"
      />
    </transition>
  </TableHeadersStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import TableSmallHeadersColumnSelector from './TableSmallHeaderSelector/TableSmallHeadersColumnSelector'
import TableSmallHeadersRowSelector from './TableSmallHeaderSelector/TableSmallHeadersRowSelector'

const TableHeadersStyled = styled('div')`
  position: relative;
  background: ${({ theme }) => theme.colors.solidBG};

  .vertical-enter-active,
  .vertical-leave-active {
    transition: transform 0.5s, opacity 0.5s;
  }
  .vertical-enter,
  .vertical-leave-to {
    transform: translateX(-100%);
    opacity: 0;
  }

  .table-details.vertical-enter,
  .table-details.vertical-leave-to {
    transform: translateX(100%);
    opacity: 0;
  }

  .vertical-leave-active {
    position: absolute;
  }
`

export default {
  props: {
    selectedRow: {
      type: Object,
    },
    selectedColumn: {
      type: Object,
    },
    prevRowDisabled: {
      type: Boolean,
      required: true,
    },
    nextRowDisabled: {
      type: Boolean,
      required: true,
    },
    prevColumnDisabled: {
      type: Boolean,
      required: true,
    },
    nextColumnDisabled: {
      type: Boolean,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
  },
  components: {
    TableHeadersStyled,
    TableSmallHeadersColumnSelector,
    TableSmallHeadersRowSelector,
  },
}
</script>
