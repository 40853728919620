<template>
  <DropdownSelectorStyled>
    <CustomSingleSelect
      :options="availableSelectors"
      :filter="optionSelected"
      :closeOnSelect="true"
      @selectedFilter="updateSelection"
      :selectedOption="preselectedOption"
      :customOptions="customOptions"
      :customLabels="customLabels"
      :shouldNotResetSelectedOption="true"
      class="select"
    >
      <template v-slot:customLabelIcon> <CalendarIcon size="1.2x" class="icon" /> </template>
    </CustomSingleSelect>
  </DropdownSelectorStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { CustomSingleSelect } from '@common/components'
import { CalendarIcon } from 'vue-feather-icons'

const DropdownSelectorStyled = styled('div')`
  margin-right: 0.5rem;
  border-radius: 8px;

  .feed_multiselect {
    width: max-content;
    .multiselect__single {
      color: ${p => p.theme.colors.normalFontColor} !important;
      .labelText {
        display: none;
      }
    }
    .multiselect__content-wrapper {
      min-width: 13rem;
      left: -8rem;
    }
    @media (min-width: 768px) {
      .labelText {
        display: inline-block !important;
      }
      .multiselect__content-wrapper {
        left: 0;
      }
    }
  }
  .select {
    border-radius: 8px;
    background: transparent;
    width: max-content;
  }

  .icon {
    color: ${({ theme }) => theme.colors.primary};
  }
`

export default {
  props: {
    availableSelectors: {
      type: Array,
      require: true,
    },
    customOptions: {
      type: String,
      required: false,
    },
    customLabels: {
      type: String,
      required: false,
    },
    preselectionId: {
      type: String,
      required: false,
    },
  },
  components: {
    DropdownSelectorStyled,
    CustomSingleSelect,
    CalendarIcon,
  },
  data() {
    return {
      preselectedOption: {},
      optionSelected: null,
    }
  },
  created() {
    if (!this.optionSelected) {
      const preselectionIndex = this.preselectionId ? this.availableSelectors.map(option => option.id).indexOf(this.preselectionId) : 0
      this.optionSelected = this.updateSelection(this.availableSelectors[preselectionIndex])
      this.preselectedOption = this.availableSelectors[preselectionIndex]
    }
  },
  methods: {
    updateSelection(args) {
      this.$emit('selectionChanged', args)
    },
  },
}
</script>
