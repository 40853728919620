var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isLarge ? _c('TableLargeV2', {
    attrs: {
      "title": _vm.title,
      "headers": _vm.headers,
      "rows": _vm.rows,
      "sorting": _vm.sorting,
      "filtersAvailable": _vm.filtersAvailable,
      "filterOptionsSelected": _vm.filterOptionsSelected,
      "dropDownSelectorsAvailable": _vm.dropDownSelectorsAvailable,
      "dropDownSelectors": _vm.dropDownSelectors,
      "customOptions": _vm.customOptions,
      "customLabels": _vm.customLabels,
      "isLoading": _vm.isLoading,
      "searchQuery": _vm.searchQuery,
      "enableSearch": _vm.enableSearch,
      "totals": _vm.totals,
      "isMobile": _vm.isMobile,
      "enableFooter": _vm.enableFooter,
      "messages": _vm.messages,
      "fixedFilter": _vm.fixedFilter,
      "filtersLoading": _vm.filtersLoading,
      "panelComponent": _vm.panelComponent,
      "hasSavedFilters": _vm.hasSavedFilters,
      "isRowSelectable": _vm.isRowSelectable,
      "selectedRowId": _vm.selectedRowId,
      "badgeText": _vm.badgeText,
      "noTitleCapitalize": _vm.noTitleCapitalize,
      "preselectionId": _vm.preselectionId,
      "disableTitleBar": _vm.disableTitleBar,
      "showLoadMore": _vm.showLoadMore,
      "totalRowCount": _vm.totalRowCount
    },
    on: {
      "tableExport": function tableExport($event) {
        return _vm.$emit('tableExport', $event);
      },
      "update:sorting": function updateSorting($event) {
        return _vm.$emit('update:sorting', $event);
      },
      "setSelectedFilterOption": function setSelectedFilterOption($event) {
        return _vm.$emit('setSelectedFilterOption', $event);
      },
      "resetFilters": function resetFilters($event) {
        return _vm.$emit('resetFilters');
      },
      "saveFilters": function saveFilters($event) {
        return _vm.$emit('saveFilters');
      },
      "deleteFilters": function deleteFilters($event) {
        return _vm.$emit('deleteFilters');
      },
      "textFilter": function textFilter($event) {
        return _vm.$emit('textFilter', $event);
      },
      "copyToClipboard": function copyToClipboard($event) {
        return _vm.$emit('copyToClipboard', $event);
      },
      "dropdownSelectorChange": function dropdownSelectorChange($event) {
        return _vm.$emit('dropdownSelectorChange', $event);
      },
      "set-selected-row-id": function setSelectedRowId($event) {
        return _vm.$emit('set-selected-row-id', $event);
      },
      "load-more-rows": function loadMoreRows($event) {
        return _vm.$emit('load-more-rows', $event);
      }
    }
  }) : _c('TableSmallV2', {
    attrs: {
      "title": _vm.title,
      "headers": _vm.headers,
      "rows": _vm.rows,
      "sorting": _vm.sorting,
      "filtersAvailable": _vm.filtersAvailable,
      "filterOptionsSelected": _vm.filterOptionsSelected,
      "dropDownSelectorsAvailable": _vm.dropDownSelectorsAvailable,
      "dropDownSelectors": _vm.dropDownSelectors,
      "customOptions": _vm.customOptions,
      "customLabels": _vm.customLabels,
      "isLoading": _vm.isLoading,
      "searchQuery": _vm.searchQuery,
      "enableSearch": _vm.enableSearch,
      "totals": _vm.totals,
      "isMobile": _vm.isMobile,
      "enableFooter": _vm.enableFooter,
      "messages": _vm.messages,
      "fixedFilter": _vm.fixedFilter,
      "panelComponent": _vm.panelComponent,
      "hasSavedFilters": _vm.hasSavedFilters,
      "badgeText": _vm.badgeText,
      "preselectionId": _vm.preselectionId,
      "disableTitleBar": _vm.disableTitleBar,
      "showLoadMore": _vm.showLoadMore,
      "totalRowCount": _vm.totalRowCount
    },
    on: {
      "tableExport": function tableExport($event) {
        return _vm.$emit('tableExport', $event);
      },
      "update:sorting": function updateSorting($event) {
        return _vm.$emit('update:sorting', $event);
      },
      "setSelectedFilterOption": function setSelectedFilterOption($event) {
        return _vm.$emit('setSelectedFilterOption', $event);
      },
      "resetFilters": function resetFilters($event) {
        return _vm.$emit('resetFilters');
      },
      "saveFilters": function saveFilters($event) {
        return _vm.$emit('saveFilters');
      },
      "deleteFilters": function deleteFilters($event) {
        return _vm.$emit('deleteFilters');
      },
      "textFilter": function textFilter($event) {
        return _vm.$emit('textFilter', $event);
      },
      "dropdownSelectorChange": function dropdownSelectorChange($event) {
        return _vm.$emit('dropdownSelectorChange', $event);
      },
      "load-more-rows": function loadMoreRows($event) {
        return _vm.$emit('load-more-rows', $event);
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }