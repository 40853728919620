<template>
  <Wrapper>
    <template>
      <div v-for="(content, index) in panelContent" :key="index">
        <span>{{ content.name }} : {{ content.data }}</span>
      </div>
    </template>
  </Wrapper>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const Wrapper = styled('div')`
  display: grid;
  background: ${({ theme }) => theme.colors.atomic.tableColumnHeaderBG};
  margin: 0.25rem 0.5rem;
`

export default {
  props: {
    panelContent: {
      type: Array,
      required: true,
    },
    customPanelComponent: {
      type: String,
      required: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customComponent: null,
    }
  },
  components: {
    Wrapper,
  },

  computed: {},
}
</script>
