var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TableSmallMainOverviewStyled', [_c('RowsStyled', {
    attrs: {
      "totals": _vm.totals
    }
  }, _vm._l(_vm.cellsFromRow, function (cells, key) {
    return _c('RowStyled', {
      key: cells[1].rowId
    }, [_c('TableDataCell', {
      key: "primary_".concat(cells[0].id),
      staticClass: "primary",
      attrs: {
        "cell": cells[0],
        "isMobile": _vm.isMobile,
        "cellIndex": key
      },
      scopedSlots: _vm._u([{
        key: "panelButton",
        fn: function fn() {
          return [_c('button', {
            key: cells[1].rowId,
            on: {
              "click": function click($event) {
                return _vm.togglePanel(cells[1].rowId);
              }
            }
          }, [_c('ChevronRightIcon', {
            class: {
              'rotate-down': _vm.id === cells[1].rowId,
              'rotate-right': _vm.id === null
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    }), _c('TableDataCell', {
      key: "dataCol_".concat(cells[1].id),
      staticClass: "dataCol",
      attrs: {
        "cell": cells[1]
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('selectRow', cells[1]);
        }
      }
    }), _vm.showPanel && _vm.id === cells[1].rowId && _vm.panelComponent ? _c('TableSubPanel', {
      key: cells[1].rowId,
      staticClass: "panel",
      attrs: {
        "panelContent": cells[1].panelContent,
        "customPanelComponent": _vm.panelComponent,
        "isMobile": _vm.isMobile
      }
    }) : _vm._e()], 1);
  }), 1), _vm.totals ? [_vm._l(_vm.cellsFromTotal, function (cells) {
    return [cells[1].sortableValue ? _c('TotalRowStyled', {
      key: cells[1].rowId
    }, [_c('TableDataCell', {
      key: "primary_".concat(cells[0].id),
      staticClass: "primary",
      attrs: {
        "cell": cells[0],
        "isMobile": _vm.isMobile
      }
    }), _c('TableDataCell', {
      key: "dataCol_".concat(cells[1].id),
      staticClass: "dataCol",
      attrs: {
        "cell": cells[1]
      }
    })], 1) : _vm._e()];
  })] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }