<template>
  <TableHeadersStyled>
    <ButtonStyled @click="$emit('prevRow')" :disabled="prevDisabled">
      <ChevronLeftIcon />
    </ButtonStyled>
    <Multiselect
      :options="rowsForSelection"
      :value="selectedRowPrimaryCol"
      trackBy="id"
      label="mobilePrimarycolLabel"
      :placeholder="$tc('row', 2)"
      :hide-selected="true"
      :select-label="''"
      :multiple="false"
      class="semi-darker"
      @select="$emit('selectRow', $event)"
    />
    <ButtonStyled @click="$emit('nextRow')" :disabled="nextDisabled">
      <ChevronRightIcon />
    </ButtonStyled>
  </TableHeadersStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { ChevronLeftIcon, ChevronRightIcon } from 'vue-feather-icons'

import { flexCenter, buttonReset } from '@styles/mixins'

import Multiselect from 'vue-multiselect'
import get from 'lodash/get'

const TableHeadersStyled = styled('div')`
  ${flexCenter}
  height: calc(3rem - 2px);
`

const ButtonStyled = styled('div')`
  ${buttonReset}
  ${flexCenter}
  color: ${p => p.theme.colors.primary};
  flex-shrink: 0;
  width: 3rem;
`

export default {
  props: {
    prevDisabled: {
      type: Boolean,
      required: true,
    },
    nextDisabled: {
      type: Boolean,
      required: true,
    },
    selectedRow: {
      type: Object,
    },
    rows: {
      type: Array,
      required: true,
    },
  },
  components: {
    TableHeadersStyled,
    ChevronLeftIcon,
    ChevronRightIcon,
    Multiselect,
    ButtonStyled,
  },
  computed: {
    rowsForSelection() {
      return this.rows.map(row => {
        const primaryCol = get(row, 'cells', []).find(c => c.isMobilePrimarycol)
        return {
          rowId: row.rowId,
          ...primaryCol,
        }
      })
    },
    selectedRowPrimaryCol() {
      return get(this.selectedRow, 'cells', []).find(c => c.isMobilePrimarycol)
    },
  },
}
</script>
