<template>
  <TableHeaderCellStyled>
    <button
      :disabled="!cell.isSortable"
      @click="$emit('update:sorting', { key: cell.sortKey, asc: isSelectedSorting ? !sorting.asc : true })"
    >
      <ChevronRightIcon :class="rotate" />
    </button>
    <div class="label" :title="label">{{ label }}</div>
    <div class="spacer" />
    <div class="sort-description" v-if="isSelectedSorting">{{ $t(`table.sorting.${cell.sortType}.${sorting.asc ? 'asc' : 'desc'}`) }}</div>
  </TableHeaderCellStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { ChevronRightIcon } from 'vue-feather-icons'
import { buttonReset, flexCenter } from '@styles/mixins'
import { get } from 'lodash'

const TableHeaderCellStyled = styled('div')`
  background: ${({ theme }) => theme.colors.atomic.tableColumnHeaderBG};
  display: grid;
  grid-template-columns: 3rem 1fr;
  grid-template-rows: 2.5rem 2rem;
  align-items: center;
  > button {
    ${buttonReset}
    ${flexCenter}
    color: ${({ theme }) => theme.colors.atomic.primary};
    padding: 0;
    > .feather {
      transition: 0.25s transform;
      &.rotate-up {
        transform: rotate(90deg);
      }
      &.rotate-down {
        transform: rotate(-90deg);
      }
    }
  }
  > .label {
    font-weight: bold;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  > .sort-description {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.atomic.whiteDarker};
  }
  &:first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  &:last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
`

export default {
  props: {
    cell: {
      type: Object,
      required: true,
    },
    sorting: {
      type: Object,
      required: true,
    },
  },
  components: {
    TableHeaderCellStyled,
    ChevronRightIcon,
  },
  computed: {
    isSelectedSorting() {
      return this.sorting.key === this.cell?.sortKey
    },
    rotate() {
      if (this.isSelectedSorting) {
        if (this.sorting.asc) {
          return 'rotate-up'
        } else {
          return 'rotate-down'
        }
      }
      return ''
    },
    label() {
      const label = get(this.cell, 'label', '')
      let unit = get(this.cell, 'unit', '')
      if (unit !== '') {
        unit = `(${unit})`
      }
      return `${label.toUpperCase()} ${unit}`
    },
  },
}
</script>
