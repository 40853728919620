var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TableHeadersStyled', [_c('ButtonStyled', {
    attrs: {
      "disabled": _vm.prevDisabled
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('prevColumn');
      }
    }
  }, [_c('ChevronLeftIcon')], 1), _c('Multiselect', {
    staticClass: "semi-darker",
    attrs: {
      "options": _vm.columns,
      "value": _vm.selectedColumn,
      "trackBy": "id",
      "label": "label",
      "placeholder": _vm.$tc('column', 2),
      "hide-selected": true,
      "select-label": '',
      "multiple": false
    },
    on: {
      "select": function select($event) {
        return _vm.$emit('selectColumn', $event);
      }
    }
  }), _c('ButtonStyled', {
    attrs: {
      "disabled": _vm.nextDisabled
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('nextColumn');
      }
    }
  }, [_c('ChevronRightIcon')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }