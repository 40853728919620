var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TableHeaderCellStyled', [_c('button', {
    attrs: {
      "disabled": !_vm.cell.isSortable
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('update:sorting', {
          key: _vm.cell.sortKey,
          asc: _vm.isSelectedSorting ? !_vm.sorting.asc : true
        });
      }
    }
  }, [_c('ChevronRightIcon', {
    class: _vm.rotate
  })], 1), _c('div', {
    staticClass: "label",
    attrs: {
      "title": _vm.label
    }
  }, [_vm._v(_vm._s(_vm.label))]), _c('div', {
    staticClass: "spacer"
  }), _vm.isSelectedSorting ? _c('div', {
    staticClass: "sort-description"
  }, [_vm._v(_vm._s(_vm.$t("table.sorting.".concat(_vm.cell.sortType, ".").concat(_vm.sorting.asc ? 'asc' : 'desc'))))]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }