var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TableSmallV2Styled', {
    attrs: {
      "totals": _vm.totals,
      "enableFooter": _vm.enableFooter,
      "badgeText": _vm.badgeText,
      "disableTitleBar": _vm.disableTitleBar
    }
  }, [!_vm.disableTitleBar ? _c('header', [!_vm.selectedRow ? _c('div', {
    staticClass: "title-bar"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('CircleBadge', {
    attrs: {
      "label": _vm.rows.length,
      "totalRowCount": _vm.totalRowCount,
      "size": "M"
    }
  }), _vm.badgeText ? _c('BadgeAtom', {
    staticClass: "badge",
    attrs: {
      "label": _vm.badgeText
    }
  }) : _vm._e()], 1) : _c('div', [_c('button', {
    staticClass: "styleless",
    on: {
      "click": function click($event) {
        return _vm.selectRow(null);
      }
    }
  }, [_c('ChevronUpIcon'), _c('span', {
    staticClass: "backTitle"
  }, [_vm._v(_vm._s(_vm.$t('actions.backToOverview')))])], 1)]), _c('div', {
    staticClass: "spacer"
  }), _vm.enableSearch ? _c('SearchIcon', {
    attrs: {
      "data-testid": "tableSmallSearch"
    },
    on: {
      "click": _vm.toggleSearchTray
    }
  }) : _vm._e(), _vm.dropDownSelectorsAvailable ? _c('div', {
    staticClass: "dropdown"
  }, [_c('DropdownSelectorV2', {
    attrs: {
      "availableSelectors": _vm.dropDownSelectors,
      "customOptions": _vm.customOptions,
      "customLabels": _vm.customLabels,
      "preselectionId": _vm.preselectionId,
      "data-testid": "tableSmallDropdownSelector"
    },
    on: {
      "selectionChanged": function selectionChanged($event) {
        return _vm.$emit('dropdownSelectorChange', $event);
      }
    }
  })], 1) : _vm._e(), _vm.filtersAvailable.length > 0 ? _c('button', {
    staticClass: "icon",
    on: {
      "click": _vm.enableFilters
    }
  }, [_c('TalpaIcon', {
    staticClass: "filter-icon",
    attrs: {
      "scope": 'Feed',
      "name": 'FilterIcon'
    }
  }), _c('CircleBadge', {
    staticClass: "filter-badge",
    attrs: {
      "label": _vm.filterOptionsSelected.length,
      "size": "S"
    }
  })], 1) : _vm._e(), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.showSearchTray ? _c('SearchTrayStyled', [_c('div', {
    staticClass: "search"
  }, [_c('SearchBar', {
    attrs: {
      "searchQuery": _vm.searchQuery,
      "placeHolder": 'actions.search',
      "data-testid": "tableSmallSearchInput"
    },
    on: {
      "filterShareList": function filterShareList($event) {
        return _vm.$emit('textFilter', $event);
      }
    }
  }), _c('XIcon', {
    attrs: {
      "size": "2x"
    },
    on: {
      "click": _vm.toggleSearchTray
    }
  })], 1)]) : _vm._e()], 1), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.showFilters ? _c('FilterOverlayStyled', [_c('div', {
    staticClass: "filter-top"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$tc('feed.filter.title')) + ":")]), _c('CircleBadge', {
    attrs: {
      "label": _vm.filterOptionsSelected.length,
      "size": "S"
    }
  }), _c('div', {
    staticClass: "spacer"
  }), _c('FilterActionStyled', {
    attrs: {
      "fixedFilter": _vm.fixedFilter
    }
  }, [_vm.fixedFilter ? [_c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltip('save'),
      expression: "tooltip('save')"
    }],
    staticClass: "styleless",
    on: {
      "click": _vm.saveFilters
    }
  }, [_c('SaveIcon', {
    attrs: {
      "size": "1.3x"
    }
  })], 1), _c('div', {
    staticClass: "divider"
  }), _c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltip('reset'),
      expression: "tooltip('reset')"
    }],
    staticClass: "hide",
    on: {
      "click": _vm.resetFilters
    }
  }, [_c('RotateCcwIcon', {
    attrs: {
      "size": "1.2x"
    }
  })], 1), _c('div', {
    staticClass: "hide"
  }), _c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltip('delete'),
      expression: "tooltip('delete')"
    }],
    staticClass: "styleless",
    attrs: {
      "disabled": !_vm.hasSavedFilters
    },
    on: {
      "click": _vm.deleteFilters
    }
  }, [_c('Trash2Icon', {
    attrs: {
      "size": "1.2x"
    }
  })], 1), _c('div', {
    staticClass: "divider"
  }), _c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltip('close'),
      expression: "tooltip('close')"
    }],
    staticClass: "icon",
    on: {
      "click": _vm.disableFilters
    }
  }, [_c('XIcon')], 1)] : [_c('button', {
    staticClass: "styleless",
    on: {
      "click": _vm.resetFilters
    }
  }, [_vm._v(" " + _vm._s(_vm.$tc('reset')) + " ")]), _c('span', [_vm._v("|")]), _c('button', {
    staticClass: "icon",
    on: {
      "click": _vm.disableFilters
    }
  }, [_c('XIcon')], 1)]], 2)], 1), _c('div', {
    staticClass: "filters"
  }, _vm._l(_vm.filtersAvailable, function (filter) {
    return _c('CustomMultiSelect', {
      key: filter.id,
      class: filter.class,
      attrs: {
        "track-by": "id",
        "openDirection": "bottom",
        "label": filter.label,
        "value": filter.value,
        "options": filter.options,
        "shouldNotResetSelectedOption": true,
        "disabled": filter.options.length === 0,
        "closeOnSelect": !filter.isMultiple,
        "searchable": filter.searchable,
        "multiple": filter.isMultiple,
        "placeholder": filter.placeholder,
        "modelName": filter.modelName,
        "maxHeight": 256,
        "optionsLimit": 6,
        "applyFilter": false,
        "internalSearch": true,
        "customTagSlot": true,
        "customNoOptionsSlot": true
      },
      on: {
        "select": function select($event) {
          return _vm.$emit('setSelectedFilterOption', $event);
        },
        "remove": function remove($event) {
          return _vm.$emit('setSelectedFilterOption', $event);
        }
      },
      scopedSlots: _vm._u([{
        key: "customLabelIcon",
        fn: function fn() {
          return [_c('ShowAsLabel', [_vm._v(_vm._s(_vm.$tc(filter.modelName, 1)) + ":")])];
        },
        proxy: true
      }, {
        key: "tag",
        fn: function fn(option) {
          return [_c('SelectedTagStyled', [filter.value.length === 1 ? _c('strong', [_vm._v(" " + _vm._s(filter.value[0].label) + " ")]) : filter.value[0].id === option.option.id ? _c('strong', [_vm._v(_vm._s(filter.placeholder) + " " + _vm._s(filter.value.length))]) : _c('span')])];
        }
      }, {
        key: "noOptions",
        fn: function fn() {
          return [_c('strong', [_vm._v(_vm._s(filter.placeholder))])];
        },
        proxy: true
      }], null, true)
    });
  }), 1)]) : _vm._e()], 1)], 1) : _vm._e(), _c('TableSmallHeaderSelector', {
    staticClass: "header-selector",
    attrs: {
      "rows": _vm.rows,
      "selectedRow": _vm.selectedRow,
      "prevRowDisabled": _vm.rows.length < 2,
      "nextRowDisabled": _vm.rows.length < 2,
      "prevColumnDisabled": _vm.columns.length < 2,
      "nextColumnDisabled": _vm.columns.length < 2,
      "selectedColumn": _vm.selectedColumn,
      "columns": _vm.columns
    },
    on: {
      "selectColumn": _vm.selectColumn,
      "prevColumn": _vm.prevColumn,
      "nextColumn": _vm.nextColumn,
      "prevRow": _vm.prevRow,
      "nextRow": _vm.nextRow,
      "selectRow": _vm.selectRow
    }
  }), _c('main', {
    attrs: {
      "id": "mobile-main"
    }
  }, [_vm.isLoading ? _c('TalpaLoaderWrapper') : _vm._e(), _vm.selectedColumn && !_vm.isLoading && _vm.rows.length ? _c('TableSmallDataRows', {
    staticClass: "data-rows",
    attrs: {
      "isLoading": _vm.isLoading,
      "selectedRow": _vm.selectedRow,
      "rows": _vm.rows,
      "columns": _vm.columns,
      "selectedColumn": _vm.selectedColumn,
      "isMobile": _vm.isMobile,
      "totals": _vm.totals,
      "panelComponent": _vm.panelComponent
    },
    on: {
      "selectRow": _vm.selectRow
    }
  }) : _vm._e(), _vm.showLoadMore ? _c('LoadMoreButtonMolecule', {
    staticClass: "loadmore",
    on: {
      "load-more-rows": function loadMoreRows($event) {
        return _vm.$emit('load-more-rows', $event);
      }
    }
  }) : !_vm.isLoading && !_vm.rows.length ? _c('NoDataStyled', [_vm._v(_vm._s(_vm.messages))]) : _vm._e(), _c('ScrollToTopButtonMolecule', {
    staticClass: "scroll-up",
    attrs: {
      "containerId": 'mobile-main',
      "scrollThreshold": 1000
    }
  })], 1), _vm.enableFooter && !_vm.isLoading && _vm.rows.length ? _c('footer', [_c('DownloadIcon', {
    on: {
      "click": function click($event) {
        return _vm.$emit('tableExport', 'csv');
      }
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }