<template>
  <TableMainStyled>
    <transition name="vertical">
      <TableSmallColumnData
        v-if="!selectedRow"
        :rows="rows"
        :columns="columns"
        :selectedColumn="selectedColumn"
        :isMobile="isMobile"
        :totals="totals"
        :panelComponent="panelComponent"
        @selectRow="$emit('selectRow', $event)"
      />
      <TableSmallRowData v-else class="table-details" :row="selectedRow" :columns="columns" @selectRow="$emit('selectRow', $event)" />
    </transition>
    <LoadingStyled v-if="isLoading">
      <TalpaLoaderWrapper :isDarker="true" />
    </LoadingStyled>
  </TableMainStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import TableSmallColumnData from './TableSmallDataRows/TableSmallColumnData'
import TableSmallRowData from './TableSmallDataRows/TableSmallRowData'
import { TalpaLoaderWrapper } from '@common/components'

const TableMainStyled = styled('div')`
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;

  .vertical-enter-active,
  .vertical-leave-active {
    transition: transform 0.5s;
    width: 100%;
  }
  .vertical-enter,
  .vertical-leave-to {
    transform: translateX(-100%);
  }

  .table-details.vertical-enter,
  .table-details.vertical-leave-to {
    transform: translateX(100%);
  }

  .vertical-leave-active {
    position: absolute;
  }
`

const LoadingStyled = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export default {
  props: {
    isLoading: {
      type: Boolean,
    },
    rows: {
      type: Array,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    selectedRow: {
      type: Object,
    },
    selectedColumn: {
      type: Object,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    totals: {
      type: Array,
      required: false,
    },
    panelComponent: {
      type: String,
      required: false,
    },
  },
  components: {
    TableMainStyled,
    TableSmallColumnData,
    TableSmallRowData,
    LoadingStyled,
    TalpaLoaderWrapper,
  },
}
</script>
