var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TableHeadersStyled', {
    class: {
      detailed: _vm.selectedRow
    }
  }, [_c('transition', {
    staticClass: "foobar",
    attrs: {
      "name": "vertical"
    }
  }, [!_vm.selectedRow ? _c('TableSmallHeadersColumnSelector', {
    attrs: {
      "prevDisabled": _vm.prevColumnDisabled,
      "nextDisabled": _vm.nextColumnDisabled,
      "selectedColumn": _vm.selectedColumn,
      "columns": _vm.columns
    },
    on: {
      "selectColumn": function selectColumn($event) {
        return _vm.$emit('selectColumn', $event);
      },
      "prevColumn": function prevColumn($event) {
        return _vm.$emit('prevColumn');
      },
      "nextColumn": function nextColumn($event) {
        return _vm.$emit('nextColumn');
      }
    }
  }) : _c('TableSmallHeadersRowSelector', {
    staticClass: "table-details",
    attrs: {
      "prevDisabled": _vm.prevRowDisabled,
      "nextDisabled": _vm.nextRowDisabled,
      "selectedRow": _vm.selectedRow,
      "rows": _vm.rows
    },
    on: {
      "selectRow": function selectRow($event) {
        return _vm.$emit('selectRow', $event);
      },
      "prevRow": function prevRow($event) {
        return _vm.$emit('prevRow');
      },
      "nextRow": function nextRow($event) {
        return _vm.$emit('nextRow');
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }