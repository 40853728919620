var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('WrapperStyled', {
    staticClass: "cell"
  }, [_vm.cell.panel ? _vm._t("panelButton") : _vm._e(), _vm.cell.component !== undefined ? _c(_vm.cell.component, {
    tag: "component",
    attrs: {
      "cell": _vm.cell,
      "isMobile": _vm.isMobile,
      "panel": _vm.cell.panel
    }
  }) : _c('PlainCellStyled', {
    attrs: {
      "isMobile": _vm.isMobile,
      "panel": _vm.cell.panel
    }
  }, [_vm._v(" " + _vm._s(_vm.cell.sortableValue) + " ")])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }