<template>
  <TableLargeV2Styled
    :totals="totals"
    :enableFooter="enableFooter"
    :displayMessage="displayMessage"
    :isLoading="isLoading"
    :fixedFilter="fixedFilter"
    :badgeText="badgeText"
    :disableTitleBar="disableTitleBar"
  >
    <header v-if="!disableTitleBar">
      <span class="title" :class="{ 'no-capitalize': noTitleCapitalize }" v-if="title">
        {{ title }}
        <CircleBadge class="filterButton" :label="rows.length" :totalRowCount="totalRowCount" size="M" />
      </span>

      <BadgeAtom :label="badgeText" class="badge" v-if="badgeText" />
      <div class="spacer" />
      <div class="search" v-if="enableSearch">
        <SearchBar
          :searchQuery="searchQuery"
          @filterShareList="$emit('textFilter', $event)"
          :placeHolder="'actions.search'"
          :isLoading="isLoading"
        />
      </div>
      <div class="dropdown" v-if="dropDownSelectorsAvailable">
        <DropdownSelectorV2
          :availableSelectors="dropDownSelectors"
          :customOptions="customOptions"
          :customLabels="customLabels"
          :preselectionId="preselectionId"
          @selectionChanged="$emit('dropdownSelectorChange', $event)"
        />
      </div>
      <button class="icon filterButton" @click="enableFilters" :disabled="isLoading" v-if="filtersAvailable.length > 0 && !fixedFilter">
        <TalpaIcon class="filter-icon" :scope="'Feed'" :name="'FilterIcon'" />
      </button>
      <transition name="fade">
        <LoadingDots v-if="filtersLoading" />
        <FilterOverlayStyled v-else-if="showFilters || (filtersAvailable.length > 0 && fixedFilter)" :fixedFilter="fixedFilter">
          <span> {{ $tc('feed.filter.title') }}:</span>
          <CircleBadge :label="filterOptionsSelected.length" size="S" />
          <div class="filters">
            <CustomMultiSelect
              v-for="filter in filtersAvailable"
              :key="filter.id"
              track-by="id"
              openDirection="bottom"
              :class="filter.class"
              :label="filter.label"
              :value="filter.value"
              :options="filter.options"
              :shouldNotResetSelectedOption="true"
              :disabled="filter.options.length === 0"
              :closeOnSelect="!filter.isMultiple"
              :searchable="filter.searchable"
              :multiple="filter.isMultiple"
              :placeholder="filter.placeholder"
              :modelName="filter.modelName"
              :maxHeight="256"
              :optionsLimit="6"
              :applyFilter="false"
              :internalSearch="true"
              :customTagSlot="true"
              :customNoOptionsSlot="true"
              @select="$emit('setSelectedFilterOption', $event)"
              @remove="$emit('setSelectedFilterOption', $event)"
            >
              <template v-slot:customLabelIcon>
                <ShowAsLabel>{{ $tc(filter.modelName, 1) }}:</ShowAsLabel>
              </template>
              <template v-slot:tag="option">
                <SelectedTagStyled>
                  <strong v-if="filter.value.length === 1">
                    {{ filter.value[0].label }}
                  </strong>
                  <strong v-else-if="filter.value[0].id === option.option.id">{{ filter.placeholder }} {{ filter.value.length }}</strong>
                  <span v-else />
                </SelectedTagStyled>
              </template>
              <template v-slot:noOptions>
                <strong>{{ filter.placeholder }}</strong>
              </template>
            </CustomMultiSelect>
          </div>
          <FilterActionStyled>
            <template v-if="fixedFilter">
              <button class="styleless" @click="$emit('copyToClipboard')" v-tooltip="tooltip('copy')">
                <CopyIcon size="1.3x" />
              </button>
              <div class="divider" />
              <button class="styleless" @click="saveFilters" v-tooltip="tooltip('save')"><SaveIcon size="1.3x" /></button>
              <div class="divider" />
              <button class="hide" @click="resetFilters" v-tooltip="tooltip('reset')"><RotateCcwIcon size="1.2x" /></button>
              <div class="hide" />
              <button class="styleless" @click="deleteFilters" v-tooltip="tooltip('delete')" :disabled="!hasSavedFilters">
                <Trash2Icon size="1.2x" />
              </button>
            </template>
            <template v-else>
              <button class="styleless" @click="resetFilters">{{ $tc('reset') }}</button>
              <div class="divider" />
              <button class="icon" @click="disableFilters">
                <XIcon />
              </button>
            </template>
          </FilterActionStyled>
        </FilterOverlayStyled>
      </transition>
    </header>
    <main id="main">
      <TalpaLoaderWrapper v-if="isLoading" />
      <template class="content" v-if="!isLoading && tableRows.length > 0">
        <div class="header">
          <TableRowStyled class="table-headers" :gridTemplateColumns="gridTemplateColumns">
            <TableHeaderCell
              v-for="cell in tableHeaderCells"
              :cell="cell"
              :key="cell.id"
              :sorting="sorting"
              @update:sorting="$emit('update:sorting', $event)"
            />
          </TableRowStyled>
        </div>

        <div class="main">
          <transition-group name="fade" ref="rows">
            <template v-for="(row, key) in tableRows">
              <TableRowStyled
                :gridTemplateColumns="gridTemplateColumns"
                :key="key + '_row'"
                :isRowSelectable="isRowSelectable"
                :isSelected="row.isSelected"
                :class="{ selected: isRowSelectable && row.isSelected }"
                @click="handleRowClick(row)"
              >
                <TableDataCell
                  class="dataCell"
                  v-for="(cell, index) in row.cells"
                  :cell="cell"
                  :key="index"
                  :isMobile="isMobile"
                  :cellIndex="index"
                >
                  <template v-slot:panelButton>
                    <button @click="togglePanel(key + '_row')" :key="key + '_row'">
                      <ChevronRightIcon :class="{ 'rotate-down': id === key + '_row', 'rotate-right': id === null }" />
                    </button>
                  </template>
                </TableDataCell>
              </TableRowStyled>

              <TableSubPanel
                :key="key"
                v-if="showPanel && id === key + '_row' && panelComponent"
                :panelContent="row.panelContent"
                :customPanelComponent="panelComponent"
              />
            </template>
          </transition-group>
          <LoadMoreButtonMolecule class="loadmore" v-if="showLoadMore" @load-more-rows="$emit('load-more-rows', $event)" />
        </div>
        <ScrollToTopButtonMolecule :containerId="'main'" :scrollThreshold="1500" />
        <div class="totals" v-if="totals && tableRows.length > 0">
          <TableTotalsStyled :gridTemplateColumns="gridTemplateColumns" v-for="row in totals" :key="row.id">
            <TableDataCell v-for="cell in row.cells" :cell="cell" :key="cell.id" :isMobile="isMobile" />
          </TableTotalsStyled>
        </div>
      </template>
      <div class="message" v-else-if="!isLoading && !tableRows.length">
        <NoDataStyled> {{ messages }} </NoDataStyled>
      </div>
    </main>
    <footer v-if="enableFooter && tableRows.length > 0">
      <DownloadIcon @click="$emit('tableExport', 'csv')" />
    </footer>
  </TableLargeV2Styled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { TalpaIcon, LoadMoreButtonMolecule, ScrollToTopButtonMolecule } from '@common/components'
import { buttonReset, flexCenter } from '@styles/mixins'
import { XIcon, DownloadIcon, SaveIcon, RotateCcwIcon, Trash2Icon, ChevronRightIcon, CopyIcon } from 'vue-feather-icons'
import chroma from 'chroma-js'
import CircleBadge from '../../Atoms/CircleBadge'
import TableHeaderCell from '../../Atoms/TableCells/TableHeaderCell.vue'
import TableDataCell from '../../Atoms/TableCells/TableDataCell.vue'
import SearchBar from '@/components/Atomic/Molecules/SearchBar'
import DropdownSelectorV2 from '@/components/Atomic/Molecules/DropdownSelectorV2'
import { CustomMultiSelect, TalpaLoaderWrapper } from '@common/components'
import { VTooltip } from 'v-tooltip'
import { LoadingDots } from '@common/components'
import TableSubPanel from './TableSubPanel.vue'
import BadgeAtom from '@/components/Atomic/Atoms/BadgeAtom'

const TableLargeV2Styled = styled('div')`
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  height: 100%;
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem auto;

  ${({ disableTitleBar }) => disableTitleBar && `grid-template-rows: auto;`};
  ${({ fixedFilter }) => fixedFilter && `grid-template-rows: 8rem auto;`};
  ${({ enableFooter, disableTitleBar }) => enableFooter && !disableTitleBar && `grid-template-rows: 3rem auto 2rem`};
  ${({ enableFooter, disableTitleBar }) => enableFooter && disableTitleBar && `grid-template-rows:  auto 2rem`};
  @media (max-width: 1850px) {
    ${({ fixedFilter }) => fixedFilter && `grid-template-rows: 12rem auto;`};
  }
  > header {
    position: relative;
    padding: 0.25rem 1rem;
    display: grid;
    grid-template-columns: max-content 1fr min-content min-content;
    ${({ badgeText }) => badgeText && `grid-template-columns: max-content max-content 1fr min-content min-content;`};
    ${({ fixedFilter }) => fixedFilter && `grid-template-columns: max-content 1fr min-content;`};
    grid-template-rows: 2.5rem;
    align-items: center;

    > .title {
      flex-grow: 1;
      display: flex;
      font-weight: bold;
      text-transform: capitalize;
      align-items: center;
      &.no-capitalize {
        text-transform: none;
      }
    }

    > .badge {
      margin-left: 0.5rem;
    }

    > .search {
      display: flex;
      justify-content: flex-end;
      > div {
        input {
          background: ${({ theme }) => theme.colors.atomic.bunker};
        }
        height: 2.5rem;
        width: 15rem;
      }
    }
    > .dropdown {
      > div {
        background: ${({ theme }) => (theme.isDark ? theme.colors.atomic.bunker : chroma(theme.colors.navFontNormal).alpha(0.2).css())};
        height: 2.5rem;
        width: auto;
      }
    }
    button.icon,
    button.styleless {
      ${buttonReset}
      ${flexCenter}
      padding: 0;
      color: ${({ theme }) => theme.colors.atomic.primary};
    }
    .hide {
      display: none;
    }
    button.icon {
      width: 1.25rem;
      height: 2rem;
      > .filter-icon {
        width: 1.25rem;
        height: 1.25rem;
        > svg {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
    .filterButton {
      margin-left: 0.5rem !important;
    }
  }
  > main {
    display: grid;
    position: relative;
    overflow: auto;
    padding-bottom: 0px;
    grid-template-areas:
      'header'
      'main'
      'totals';
    grid-template-rows: 5rem auto ${p => (p.totals ? '2.5rem' : '0')};
    ${({ displayMessage }) =>
      displayMessage &&
      `grid-template-areas:
      'message';
      grid-template-rows: auto`};

    .header {
      grid-area: header;
      position: sticky;
      top: 0;
      z-index: 1;
    }
    .main {
      grid-area: main;
      padding-bottom: 0.5rem;
      button.icon,
      button.styleless {
        ${buttonReset}
        ${flexCenter}
      padding: 0;
        color: ${({ theme }) => theme.colors.atomic.primary};
      }
      .loadmore {
        position: absolute;
      }
    }
    .totals {
      position: sticky;
      bottom: 0;
      grid-area: totals;
      height: 40px;
    }
    .message {
      grid-area: message;
      display: flex;
      justify-content: center;
      color: ${({ theme }) => theme.colors.atomic.textMain};
    }
  }
  > footer {
    background: ${({ theme }) => theme.colors.atomic.tableBG};
    position: relative;
    padding: 0.25rem 1rem;
    justify-content: flex-end;
    display: flex;
    > .feather {
      color: ${({ theme }) => theme.colors.atomic.primary};
      align-self: center;
      cursor: pointer;
    }
  }
`

const TableRowStyled = styled('div')`
  display: grid;
  padding: 0 0.5rem;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  grid-auto-rows: minmax(2rem, min-content);
  grid-gap: 0.25rem;

  &.table-headers {
    position: sticky;
    padding-bottom: 0.5rem;
    top: 0;
  }
  &.selected {
    > .cell {
      background: ${props => props.theme.colors.atomic.primary};
      color: ${props => props.theme.colors.atomic.white};
    }
  }
  &:hover {
    > .cell {
      background: ${props => props.theme.colors.atomic.tableColumnHeaderBG};

      ${props =>
        props.isRowSelectable &&
        `background: ${props.theme.colors.primaryActive};
     color: ${props.theme.colors.atomic.white};
     cursor: pointer;`}
    }
  }

  .dataCell > button {
    ${buttonReset}
    ${flexCenter}
    color: ${({ theme }) => theme.colors.atomic.primary};
    padding-left: 1rem;
    > .feather {
      transition: 0.25s transform;
      &.rotate-right {
        transform: rotate(0deg);
      }
      &.rotate-down {
        transform: rotate(90deg);
      }
    }
  }
`
const NoDataStyled = styled('div')`
  display: flex;
  padding: 0 0.5rem;
  align-items: center;
`
const FilterOverlayStyled = styled('div')`
  position: absolute;
  top: 49px;
  left: 0;
  z-index: 50;
  width: calc(100% - 2rem);
  padding: 1rem;
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  display: grid;
  grid-template-columns: min-content min-content 1fr min-content;
  ${({ fixedFilter }) => fixedFilter && `grid-template-columns: min-content min-content 1fr max-content;`};
  grid-template-rows: 2rem;
  grid-gap: 0.5rem;
  align-items: center;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.primary};
  @media (max-width: 1850px) {
    ${({ fixedFilter }) => fixedFilter && `grid-template-rows: 6rem;`};
  }
  .filters {
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-columns: min-content;
    grid-auto-flow: column;
    grid-gap: 1rem;
    @media (max-width: 1850px) {
      ${({ fixedFilter }) =>
        fixedFilter &&
        ` grid-template-rows: 1fr 1fr;
      grid-auto-columns: 1fr;`};
    }
    .filter-health {
      .multiselect {
        .multiselect__tags {
          min-width: 10rem !important;
        }
      }
    }
    .filter-category,
    .filter-dashboard-type,
    .filter-dashboard-title {
      .multiselect {
        .multiselect__tags {
          min-width: 20rem !important;
        }
      }
    }
    .filter-component {
      .multiselect {
        .multiselect__tags {
          min-width: 22rem !important;
        }
      }
    }
    .filter-code,
    .filter-severity,
    .filter-error-type {
      .multiselect {
        .multiselect__tags {
          min-width: 9rem !important;
        }
      }
    }
    .filter-source-geofence,
    .filter-target-geofence {
      .multiselect {
        .multiselect__tags {
          min-width: 15rem !important;
        }
      }
    }
    .filter {
      .multiselect {
        min-height: 0;
        margin: 0;
        color: ${({ theme }) => theme.colors.atomic.textMain};
        .multiselect__tags {
          display: flex;
          align-items: center;
          background: ${({ theme }) => theme.colors.atomic.bunkerLight};
          &:hover {
            background: ${({ theme }) => theme.colors.atomic.tableColumnHeaderBG};
          }
          border-radius: 3rem;
          padding: 0.1rem 2.25rem 0.1rem 1rem;
          min-width: 13rem;
          min-height: 0;
          height: 2.5rem;
          .multiselect__single {
            top: 0rem;
            font-size: 14px;
            font-weight: bold;
            padding: 0;
            margin: 0;
          }
          .multiselect__element {
            background: ${({ theme }) => theme.colors.atomic.mirage};
          }
          .multiselect__placeholder {
            padding: 0;
            margin: 0;
            color: ${({ theme }) => theme.colors.atomic.textMain};
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
    }
  }
`

const ShowAsLabel = styled('span')`
  color: ${({ theme }) => theme.colors.atomic.textMain};
`
const SelectedTagStyled = styled('span')`
  color: ${({ theme }) => theme.colors.atomic.primary};
`

const TableTotalsStyled = styled('div')`
  position: sticky;
  bottom: 0px;
  display: grid;
  padding: 0 0.5rem;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  grid-auto-rows: minmax(2rem, min-content);
  grid-gap: 0.25rem;
  > .cell {
    border-top: solid 2px ${({ theme }) => theme.colors.atomic.gunmetal};
    background: ${({ theme }) => theme.colors.atomic.tableTotal};
  }
`

const FilterActionStyled = styled('div')`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.atomic.toolsMenuBG};
  margin-left: 1rem;
  ${({ isMobile }) => isMobile && 'margin-left: 0; margin-bottom: 0.5rem; position:absolute; bottom:3.5rem;'};
  border-radius: 8px;
  justify-content: space-evenly;
  color: ${({ theme }) => theme.colors.primary};
  box-shadow: 0px 3px 6px ${({ theme }) => chroma(theme.colors.black).alpha(0.32).css()};
  border: 1px solid
    ${({ theme }) => (theme.isDark ? chroma(theme.colors.white).alpha(0.16).css() : chroma(theme.colors.black).alpha(0.16).css())};
  padding: 0 0.5rem;
  .divider {
    display: flex;
    margin: 0.5rem;
    width: 1px;
    height: 25px;
    background: ${({ theme }) => theme.colors.atomic.divider};
  }
`

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    sorting: {
      type: Object,
      required: true,
    },
    filtersAvailable: {
      type: Array,
      required: true,
    },
    filterOptionsSelected: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
    },
    searchQuery: {
      type: String,
      required: false,
    },
    enableSearch: {
      type: Boolean,
      default: false,
      required: true,
    },
    totals: {
      type: Array,
      required: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    enableFooter: {
      type: Boolean,
      default: false,
    },
    messages: {
      type: String,
      required: false,
    },
    dropDownSelectorsAvailable: {
      type: Boolean,
      default: false,
    },
    dropDownSelectors: {
      type: Array,
      require: false,
    },
    customOptions: {
      type: String,
      required: false,
    },
    customLabels: {
      type: String,
      required: false,
    },
    fixedFilter: {
      type: Boolean,
      default: false,
    },
    filtersLoading: {
      type: Boolean,
      default: false,
      required: false,
    },
    panelComponent: {
      type: String,
      required: false,
    },
    hasSavedFilters: {
      type: Boolean,
      default: false,
      required: false,
    },
    isRowSelectable: {
      type: Boolean,
      default: false,
      required: false,
    },
    selectedRowId: {
      type: String,
      required: false,
    },
    badgeText: {
      type: String,
      required: false,
    },
    noTitleCapitalize: {
      type: Boolean,
      default: false,
    },
    preselectionId: {
      type: String,
      required: false,
    },
    disableTitleBar: {
      type: Boolean,
      default: false,
    },
    showLoadMore: {
      type: Boolean,
      default: false,
    },
    totalRowCount: {
      type: Number,
    },
  },
  directives: {
    tooltip: VTooltip,
  },
  components: {
    TableLargeV2Styled,
    TalpaIcon,
    TableDataCell,
    TableHeaderCell,
    TableRowStyled,
    FilterOverlayStyled,
    CircleBadge,
    XIcon,
    DownloadIcon,
    CustomMultiSelect,
    ShowAsLabel,
    TalpaLoaderWrapper,
    SearchBar,
    TableTotalsStyled,
    NoDataStyled,
    DropdownSelectorV2,
    FilterActionStyled,
    SaveIcon,
    RotateCcwIcon,
    Trash2Icon,
    LoadingDots,
    TableSubPanel,
    ChevronRightIcon,
    SelectedTagStyled,
    BadgeAtom,
    LoadMoreButtonMolecule,
    ScrollToTopButtonMolecule,
    CopyIcon,
  },
  data() {
    return {
      showFilters: false,
      showPanel: false,
      id: null,
      loaded: false,
    }
  },
  mounted() {
    this.scrollToSelectedRow()
  },
  watch: {
    $route: {
      handler(prev, current) {
        // handles only AssetHealthInsights table for now
        const samePath = prev?.path === current?.path
        const sameCardId = prev?.query?.cardId === current?.query?.cardId
        if (samePath && sameCardId) {
          // apparently only a different row was clicked,
          // so don't annoy user with scroll
        } else {
          this.scrollToSelectedRow()
        }
      },
    },
  },
  computed: {
    tableRows() {
      return this.rows.map(item => ({
        ...item,
      }))
    },
    tableHeaderCells() {
      return this.headers
    },
    gridTemplateColumns() {
      return this.headers
        .map(m => {
          if (m?.size === 'xsmall') {
            return '8rem'
          } else if (m?.size === 'small') {
            return 'minmax(10rem, 1fr)'
          } else if (m?.size === 'medium') {
            return 'minmax(15rem, 1fr)'
          } else if (m?.size === 'large') {
            return 'minmax(30rem, 1fr)'
          }
          return 'minmax(auto, 1fr)'
        })
        .join(' ')
    },
    displayMessage() {
      return this.messages !== '' || this.isLoading
    },
  },
  methods: {
    scrollToSelectedRow() {
      this.$nextTick(() => {
        if (this.isRowSelectable) {
          const allRows = this.$refs.rows.$el
          if (allRows) {
            const selectedRow = allRows.querySelector('.selected')
            if (selectedRow) {
              selectedRow.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
            }
          }
        }
      })
    },
    handleRowClick(row) {
      if (this.isRowSelectable) {
        this.$emit('set-selected-row-id', row.id)
      }
    },
    tooltip(mode) {
      return {
        content: this.toolTipContent(mode),
        placement: 'top',
        classes: 'dashboard',
        trigger: 'hover',
      }
    },
    toolTipContent(mode) {
      if (mode === 'save') {
        return this.$tc('table.filter.tooltip.save')
      } else if (mode === 'reset') {
        return this.$tc('table.filter.tooltip.reset')
      } else if (mode === 'delete') {
        return this.$tc('table.filter.tooltip.delete')
      } else if (mode === 'Copy') {
        return this.$tc('table.filter.tooltip.copy')
      }
    },
    enableFilters() {
      this.showFilters = !this.showFilters
    },
    resetFilters() {
      this.$emit('resetFilters')
    },
    saveFilters() {
      this.$emit('saveFilters')
    },
    deleteFilters() {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        confirmText: this.$t('table.filter.deleteInfoMessage'),
        onConfirm: this.confirmDelete,
      })
    },
    confirmDelete() {
      this.$emit('deleteFilters')
      return 'delete'
    },
    disableFilters() {
      this.showFilters = false
    },
    togglePanel(id) {
      if (this.id === id) {
        this.id = null
        this.showPanel = false
      } else {
        this.id = id
        this.showPanel = true
      }
    },
    selectedRowDetails(row) {
      this.loaded = true
      this.$emit('selectedRow', row.rowId)
    },
  },
}
</script>
