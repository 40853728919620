var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Wrapper', [_vm._l(_vm.panelContent, function (content, index) {
    return _c('div', {
      key: index
    }, [_c('span', [_vm._v(_vm._s(content.name) + " : " + _vm._s(content.data))])]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }