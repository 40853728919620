var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TableLargeV2Styled', {
    attrs: {
      "totals": _vm.totals,
      "enableFooter": _vm.enableFooter,
      "displayMessage": _vm.displayMessage,
      "isLoading": _vm.isLoading,
      "fixedFilter": _vm.fixedFilter,
      "badgeText": _vm.badgeText,
      "disableTitleBar": _vm.disableTitleBar
    }
  }, [!_vm.disableTitleBar ? _c('header', [_vm.title ? _c('span', {
    staticClass: "title",
    class: {
      'no-capitalize': _vm.noTitleCapitalize
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _c('CircleBadge', {
    staticClass: "filterButton",
    attrs: {
      "label": _vm.rows.length,
      "totalRowCount": _vm.totalRowCount,
      "size": "M"
    }
  })], 1) : _vm._e(), _vm.badgeText ? _c('BadgeAtom', {
    staticClass: "badge",
    attrs: {
      "label": _vm.badgeText
    }
  }) : _vm._e(), _c('div', {
    staticClass: "spacer"
  }), _vm.enableSearch ? _c('div', {
    staticClass: "search"
  }, [_c('SearchBar', {
    attrs: {
      "searchQuery": _vm.searchQuery,
      "placeHolder": 'actions.search',
      "isLoading": _vm.isLoading
    },
    on: {
      "filterShareList": function filterShareList($event) {
        return _vm.$emit('textFilter', $event);
      }
    }
  })], 1) : _vm._e(), _vm.dropDownSelectorsAvailable ? _c('div', {
    staticClass: "dropdown"
  }, [_c('DropdownSelectorV2', {
    attrs: {
      "availableSelectors": _vm.dropDownSelectors,
      "customOptions": _vm.customOptions,
      "customLabels": _vm.customLabels,
      "preselectionId": _vm.preselectionId
    },
    on: {
      "selectionChanged": function selectionChanged($event) {
        return _vm.$emit('dropdownSelectorChange', $event);
      }
    }
  })], 1) : _vm._e(), _vm.filtersAvailable.length > 0 && !_vm.fixedFilter ? _c('button', {
    staticClass: "icon filterButton",
    attrs: {
      "disabled": _vm.isLoading
    },
    on: {
      "click": _vm.enableFilters
    }
  }, [_c('TalpaIcon', {
    staticClass: "filter-icon",
    attrs: {
      "scope": 'Feed',
      "name": 'FilterIcon'
    }
  })], 1) : _vm._e(), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.filtersLoading ? _c('LoadingDots') : _vm.showFilters || _vm.filtersAvailable.length > 0 && _vm.fixedFilter ? _c('FilterOverlayStyled', {
    attrs: {
      "fixedFilter": _vm.fixedFilter
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$tc('feed.filter.title')) + ":")]), _c('CircleBadge', {
    attrs: {
      "label": _vm.filterOptionsSelected.length,
      "size": "S"
    }
  }), _c('div', {
    staticClass: "filters"
  }, _vm._l(_vm.filtersAvailable, function (filter) {
    return _c('CustomMultiSelect', {
      key: filter.id,
      class: filter.class,
      attrs: {
        "track-by": "id",
        "openDirection": "bottom",
        "label": filter.label,
        "value": filter.value,
        "options": filter.options,
        "shouldNotResetSelectedOption": true,
        "disabled": filter.options.length === 0,
        "closeOnSelect": !filter.isMultiple,
        "searchable": filter.searchable,
        "multiple": filter.isMultiple,
        "placeholder": filter.placeholder,
        "modelName": filter.modelName,
        "maxHeight": 256,
        "optionsLimit": 6,
        "applyFilter": false,
        "internalSearch": true,
        "customTagSlot": true,
        "customNoOptionsSlot": true
      },
      on: {
        "select": function select($event) {
          return _vm.$emit('setSelectedFilterOption', $event);
        },
        "remove": function remove($event) {
          return _vm.$emit('setSelectedFilterOption', $event);
        }
      },
      scopedSlots: _vm._u([{
        key: "customLabelIcon",
        fn: function fn() {
          return [_c('ShowAsLabel', [_vm._v(_vm._s(_vm.$tc(filter.modelName, 1)) + ":")])];
        },
        proxy: true
      }, {
        key: "tag",
        fn: function fn(option) {
          return [_c('SelectedTagStyled', [filter.value.length === 1 ? _c('strong', [_vm._v(" " + _vm._s(filter.value[0].label) + " ")]) : filter.value[0].id === option.option.id ? _c('strong', [_vm._v(_vm._s(filter.placeholder) + " " + _vm._s(filter.value.length))]) : _c('span')])];
        }
      }, {
        key: "noOptions",
        fn: function fn() {
          return [_c('strong', [_vm._v(_vm._s(filter.placeholder))])];
        },
        proxy: true
      }], null, true)
    });
  }), 1), _c('FilterActionStyled', [_vm.fixedFilter ? [_c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltip('copy'),
      expression: "tooltip('copy')"
    }],
    staticClass: "styleless",
    on: {
      "click": function click($event) {
        return _vm.$emit('copyToClipboard');
      }
    }
  }, [_c('CopyIcon', {
    attrs: {
      "size": "1.3x"
    }
  })], 1), _c('div', {
    staticClass: "divider"
  }), _c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltip('save'),
      expression: "tooltip('save')"
    }],
    staticClass: "styleless",
    on: {
      "click": _vm.saveFilters
    }
  }, [_c('SaveIcon', {
    attrs: {
      "size": "1.3x"
    }
  })], 1), _c('div', {
    staticClass: "divider"
  }), _c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltip('reset'),
      expression: "tooltip('reset')"
    }],
    staticClass: "hide",
    on: {
      "click": _vm.resetFilters
    }
  }, [_c('RotateCcwIcon', {
    attrs: {
      "size": "1.2x"
    }
  })], 1), _c('div', {
    staticClass: "hide"
  }), _c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: _vm.tooltip('delete'),
      expression: "tooltip('delete')"
    }],
    staticClass: "styleless",
    attrs: {
      "disabled": !_vm.hasSavedFilters
    },
    on: {
      "click": _vm.deleteFilters
    }
  }, [_c('Trash2Icon', {
    attrs: {
      "size": "1.2x"
    }
  })], 1)] : [_c('button', {
    staticClass: "styleless",
    on: {
      "click": _vm.resetFilters
    }
  }, [_vm._v(_vm._s(_vm.$tc('reset')))]), _c('div', {
    staticClass: "divider"
  }), _c('button', {
    staticClass: "icon",
    on: {
      "click": _vm.disableFilters
    }
  }, [_c('XIcon')], 1)]], 2)], 1) : _vm._e()], 1)], 1) : _vm._e(), _c('main', {
    attrs: {
      "id": "main"
    }
  }, [_vm.isLoading ? _c('TalpaLoaderWrapper') : _vm._e(), !_vm.isLoading && _vm.tableRows.length > 0 ? [_c('div', {
    staticClass: "header"
  }, [_c('TableRowStyled', {
    staticClass: "table-headers",
    attrs: {
      "gridTemplateColumns": _vm.gridTemplateColumns
    }
  }, _vm._l(_vm.tableHeaderCells, function (cell) {
    return _c('TableHeaderCell', {
      key: cell.id,
      attrs: {
        "cell": cell,
        "sorting": _vm.sorting
      },
      on: {
        "update:sorting": function updateSorting($event) {
          return _vm.$emit('update:sorting', $event);
        }
      }
    });
  }), 1)], 1), _c('div', {
    staticClass: "main"
  }, [_c('transition-group', {
    ref: "rows",
    attrs: {
      "name": "fade"
    }
  }, [_vm._l(_vm.tableRows, function (row, key) {
    return [_c('TableRowStyled', {
      key: key + '_row',
      class: {
        selected: _vm.isRowSelectable && row.isSelected
      },
      attrs: {
        "gridTemplateColumns": _vm.gridTemplateColumns,
        "isRowSelectable": _vm.isRowSelectable,
        "isSelected": row.isSelected
      },
      on: {
        "click": function click($event) {
          return _vm.handleRowClick(row);
        }
      }
    }, _vm._l(row.cells, function (cell, index) {
      return _c('TableDataCell', {
        key: index,
        staticClass: "dataCell",
        attrs: {
          "cell": cell,
          "isMobile": _vm.isMobile,
          "cellIndex": index
        },
        scopedSlots: _vm._u([{
          key: "panelButton",
          fn: function fn() {
            return [_c('button', {
              key: key + '_row',
              on: {
                "click": function click($event) {
                  return _vm.togglePanel(key + '_row');
                }
              }
            }, [_c('ChevronRightIcon', {
              class: {
                'rotate-down': _vm.id === key + '_row',
                'rotate-right': _vm.id === null
              }
            })], 1)];
          },
          proxy: true
        }], null, true)
      });
    }), 1), _vm.showPanel && _vm.id === key + '_row' && _vm.panelComponent ? _c('TableSubPanel', {
      key: key,
      attrs: {
        "panelContent": row.panelContent,
        "customPanelComponent": _vm.panelComponent
      }
    }) : _vm._e()];
  })], 2), _vm.showLoadMore ? _c('LoadMoreButtonMolecule', {
    staticClass: "loadmore",
    on: {
      "load-more-rows": function loadMoreRows($event) {
        return _vm.$emit('load-more-rows', $event);
      }
    }
  }) : _vm._e()], 1), _c('ScrollToTopButtonMolecule', {
    attrs: {
      "containerId": 'main',
      "scrollThreshold": 1500
    }
  }), _vm.totals && _vm.tableRows.length > 0 ? _c('div', {
    staticClass: "totals"
  }, _vm._l(_vm.totals, function (row) {
    return _c('TableTotalsStyled', {
      key: row.id,
      attrs: {
        "gridTemplateColumns": _vm.gridTemplateColumns
      }
    }, _vm._l(row.cells, function (cell) {
      return _c('TableDataCell', {
        key: cell.id,
        attrs: {
          "cell": cell,
          "isMobile": _vm.isMobile
        }
      });
    }), 1);
  }), 1) : _vm._e()] : !_vm.isLoading && !_vm.tableRows.length ? _c('div', {
    staticClass: "message"
  }, [_c('NoDataStyled', [_vm._v(" " + _vm._s(_vm.messages) + " ")])], 1) : _vm._e()], 2), _vm.enableFooter && _vm.tableRows.length > 0 ? _c('footer', [_c('DownloadIcon', {
    on: {
      "click": function click($event) {
        return _vm.$emit('tableExport', 'csv');
      }
    }
  })], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }