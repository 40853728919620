<template>
  <TableSmallMainDetailsStyled>
    <PrimayColStyled>
      <TableDataCell class="value" :cell="primaryCol" />
    </PrimayColStyled>
    <RowsStyled>
      <RowStyled v-for="r in rows" :key="r[0].id">
        <span class="name">{{ r[0].label }}</span>
        <TableDataCell class="value" :cell="r[1]" :key="`dataCol_${r[1].id}`" />
      </RowStyled>
    </RowsStyled>
  </TableSmallMainDetailsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'

import { flexCenter } from '@styles/mixins'
import localesMixin from '@/mixins/locales'
import TableDataCell from '../../../../Atoms/TableCells/TableDataCell'

const TableSmallMainDetailsStyled = styled('div')`
  display: grid;
  height: 100%;
  grid-gap: 2px;
  grid-template-columns: 1fr;
  grid-template-rows: 4rem 1fr;
`

const RowsStyled = styled('div')`
  display: grid;
  height: 100%;
  overflow-y: auto;
  grid-gap: 2px;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(2.5rem, auto);
`

const RowStyled = styled('div')`
  display: grid;
  grid-template-areas: 'name value';
  grid-template-rows: 2.5rem;
  grid-template-columns: 1fr 2fr;
  background: ${({ theme }) => theme.colors.solidBG};
  padding: 0.5rem;
  .name {
    ${flexCenter}
    justify-content: flex-start;
    grid-area: name;
    text-transform: uppercase;
  }
  .value {
    ${flexCenter}
    justify-content: flex-end;
    grid-area: value;
  }
`

const PrimayColStyled = styled('div')`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.solidBG};
`

export default {
  mixins: [localesMixin],
  props: {
    row: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
  },
  components: {
    TableSmallMainDetailsStyled,
    RowsStyled,
    RowStyled,
    TableDataCell,
    PrimayColStyled,
  },
  computed: {
    rows() {
      return this.columns.map(column => {
        const cell = get(this.row, 'cells', []).find(c => c.headerId === column.id)
        return [column, cell]
      })
    },
    primaryCol() {
      return get(this.row, 'cells', []).find(c => c.isMobilePrimarycol === true)
    },
  },
}
</script>
