var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TableSmallMainDetailsStyled', [_c('PrimayColStyled', [_c('TableDataCell', {
    staticClass: "value",
    attrs: {
      "cell": _vm.primaryCol
    }
  })], 1), _c('RowsStyled', _vm._l(_vm.rows, function (r) {
    return _c('RowStyled', {
      key: r[0].id
    }, [_c('span', {
      staticClass: "name"
    }, [_vm._v(_vm._s(r[0].label))]), _c('TableDataCell', {
      key: "dataCol_".concat(r[1].id),
      staticClass: "value",
      attrs: {
        "cell": r[1]
      }
    })], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }